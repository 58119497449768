<template>
  <div class="ThreeTerminal">
    <!-- <div>
      <div class="title2 mt-4 ml-4">
        <img src="@/assets/img/private-deploy-logo.svg" alt="" />
        <div>
          <span>中国建筑第八工程局有限公司</span>
          <p class="english">
            CHINA CONSTRUCTION EIGHTH ENGINEERING DIVISION COLTD
          </p>
        </div>
      </div>
      <div class="right-header" @click="logout">
        <i class="el-icon-switch-button"></i>
      </div>
    </div> -->
    <!-- <p class="title1">柯桥未来医学中心项目</p> -->
    <div class="left-header fixed flex items-center" v-if="num != 10">
      <!-- <img
        v-if="groupLogo"
        :src="groupLogo"
        class="company-logo"
        style="width: 32px; height: 32px"
      />
      <img
        v-else
        src="@/assets/img/new/logo-icon.svg"
        class="company-logo"
        style="width: 32px; height: 32px"
      />
      <div class="short-name">{{ companyShortName }}</div> -->
      <!-- <span class="back-home cursor-pointer text-center" v-if="num != 3" @click="num = 3">返回首页</span> -->
      <img
        class="cursor-pointer"
        v-if="num != 3 && num != 8"
        @click="goback"
        src="@/assets/img/new/global-back-icon.svg"
      />
    </div>
    <div class="right-header" style="display: none">
      <div
        class="test"
        :class="showDetail ? 'dropdown-enter-active' : 'dropdown-leave-active'"
      >
        <!-- <span class="back-home" @click="num = 3"
          >返回首页</span
        > -->
        <div
          v-for="(item, index) in btnList"
          :key="index"
          class="btn-warp"
          :class="num == index ? 'btn-warp-active' : ''"
          @click="viewChange(index)"
        >
          <span class="btn-item">{{ item }}</span>
        </div>
      </div>
      <i
        class="el-icon-setting"
        v-if="judge"
        @click="
          showDetail = !showDetail;
          !showDetail ? (num = 3) : '';
        "
      ></i>
      <i class="el-icon-switch-button" @click="logout"></i>
    </div>
    <global-header
      :administrationFlag="administrationFlag"
      @setup="setup"
    ></global-header>
    <!-- 切换产品 -->
    <div v-if="num == 3">
      <p class="title1">工程质量数智管理平台</p>
      <div class="companys relative flex items-center justify-between">
        <div style="padding-left: 16px">当前企业：{{ groupName }}</div>
        <!-- <div class="companys-btn text-center cursor-pointer" @click="num = 8">切换企业</div> -->
        <div class="companys-btn text-center cursor-pointer" @click="clickOpen()">
          切换
          <i
            class="el-icon-arrow-down"
            :class="{ 'el-arrow-down-icon-active': isShow }"
          ></i>
        </div>
        <div class="pagedialog" v-if="isShow">
          <div>
            <div
              class="pageItem flex items-center cursor-pointer"
              v-for="(item, index) in groupList"
              :key="index"
              @click="clickSelect(item)"
            >
              <img class="page-img" :src="item.logoUrl ? item.logoUrl : require('@/assets/img/new/logo-icon.svg')" />
              <div class="page-name">{{ item.groupName }}</div>
              <img
                v-if="item.adminFlag"
                class="img-edit ml-4 hidden"
                src="@/assets/img/teamNew/download.svg"
                @click.stop="clickEdit(item)"
              />
            </div>
            <div
              class="pageItem pageItem2 flex items-center justify-center cursor-pointer"
              @click="clickEdit(null)"
            >
              <div class="pageBtn">创建团队/企业</div>
            </div>
          </div>
        </div>
      </div>
      <el-carousel
        :autoplay="false"
        trigger="click"
        arrow="never"
        type="card"
        height="518px"
        indicator-position="none"
        @change="carouselChange"
      >
        <el-carousel-item
          v-for="item in itemList"
          :key="item.index"
          @click.native="change(item.index)"
          :style="{
            background: `url(${item.src}) no-repeat center`,
            backgroundSize: '100% 100%',
            border: '1px solid inset #00e3ff',
          }"
        >
          <p class="card-title">{{ item.title }}</p>
          <p class="card-content">{{ item.content }}</p>
        </el-carousel-item>
      </el-carousel>
      <div class="foot-warp">
        <div class="foot">
          <img src="@/assets/img/hmyl-private-deploy-logo.png" />
          <span>www.abuildingcloud.com</span>
        </div>
        <p class="internet-content-crovider">
          Copyright ©版权所有 豪米优量科技 浙ICP备202209088号
        </p>
      </div>
    </div>

    <!-- 角色管理 -->
    <role-admin
      v-else-if="num == 0"
      class="center-piece"
      :tabList="tabList"
      :treeData="treeData"
    />
    <!-- 用户管理 -->
    <user-admin
      v-else-if="num == 1"
      class="center-piece"
      :companyAndRole="companyAndRole"
    />
    <admin-first v-else-if="num == 5" />
    <!-- 修改密码 -->
    <change-password v-else-if="num == 6" @back="setup"></change-password>
    <!-- 消息提醒 -->
    <message-admin
      v-else-if="num == 7"
      class="center-piece"
      @back="setup"
    ></message-admin>
    <!-- 切换企业 -->
    <company-select
      v-else-if="num == 8"
      class="center-piece"
      @back="setup"
    ></company-select>
    <!-- 创建团队（企业） -->
    <team-create
      v-else-if="num == 9 || num == 10"
      :title="title"
      :teamName="teamName"
      :logoUrl="logoUrl"
      :currentGroupItem="currentGroupItem"
      @back="setup"
    ></team-create>
    <!-- 组织机构 -->
    <institutional-framework v-else class="center-piece" />

    <el-dialog
      :visible.sync="dialogVisible"
      width="618px"
      append-to-body
      custom-class="black-theme"
      title="发起邀请"
      :modal="false"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div>
        <div class="invitations-content flex items-center justify-between">
          <div>我的邀请码[{{ invitationCode }}]</div>
          <div class="invitation-btn text-center cursor-pointer" @click="copy">
            复制邀请码
          </div>
        </div>
      </div>
    </el-dialog>
    <new-user-alerts ref="NUA"></new-user-alerts>

    <el-dialog
      :visible.sync="dialogOverVisible"
      width="618px"
      append-to-body
      custom-class="black-theme fsdfssdfsdf"
      title="团队移交"
      :modal="false"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div>
        <div class="p1 p-4">
          <div class="p1-c">
            <div style="margin-bottom: 10px">
              当前团队（企业）：
              <el-select
                v-model="value2"
                placeholder="请选择"
                :popper-append-to-body="false"
                popper-class="select"
                @change="selectChange"
              >
                <el-option
                  v-for="item in groupData"
                  :key="item.id"
                  :label="item.groupName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              （移交团队之后，你将降级成为团队成员。被移交对象成为新的团队所有者！）
            </div>
          </div>
        </div>
        <div class="p2">
          <div class="p2-t mb-4">请选择移交对象</div>
          <div class="p2-c overflow-y-auto overflow-x-hidden">
            <div
              class="p2-item flex items-center justify-between cursor-pointer"
              v-for="(item, index) in dataList"
              :key="index"
            >
              <div class="flex items-center">
                <div class="p2-tag">{{ item.realname.substring(0, 1) }}</div>
                <div class="p2-name">{{ item.realname }}</div>
              </div>
              <el-radio v-model="selectId" :label="item.id">{{
                item.v
              }}</el-radio>
            </div>
          </div>
        </div>
        <div class="dialog-footer flex items-center justify-end">
          <div
            class="cancel-btn cbtn text-center cursor-pointer mr-4"
            @click="clickClose"
          >
            取消
          </div>
          <div
            class="confirm-btn cbtn text-center cursor-pointer"
            @click="clickConfirm"
          >
            确定
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import axios from "axios";
import NewUserAlerts from "./ThreeTerminal/NewUserAlerts.vue";
import { yzlUrl, xtptUrl, scslUrl } from "@/utils/services.js";
import userAdmin from "./ThreeTerminal/userAdmin.vue";
import roleAdmin from "./ThreeTerminal/roleAdmin.vue";
import institutionalFramework from "./ThreeTerminal/institutionalFramework.vue";
import changePassword from "./ThreeTerminal/changePassword.vue";
import companySelect from "./ThreeTerminal/companySelect.vue";
import messageAdmin from "./ThreeTerminal/messageAdmin.vue";
import teamCreate from "./ThreeTerminal/teamCreate.vue";
import { posts, gets, get } from "@/utils/http.js";
import * as sysTool from "@/assets/js/systemTool";
import adminFirst from "./AdminFirst.vue";
import GlobalHeader from "@/components/GlobalHeader/GlobalHeader.vue";
export default {
  components: {
    userAdmin,
    roleAdmin,
    institutionalFramework,
    adminFirst,
    GlobalHeader,
    changePassword,
    messageAdmin,
    companySelect,
    NewUserAlerts,
    teamCreate,
  },
  data() {
    return {
      num: 3,
      lockState: true,
      dialogVisible: false,
      dialogOverVisible: false,
      invitationCode: "",
      btnList: ["角色管理", "用户管理", "组织机构"],
      value: 1,
      itemList: [
        {
          index: 0,
          title: "优量云资料",
          content: "白领资料员的标配",
          src: require("@/assets/img/yzl-ikon.png"),
        },
        {
          index: 1,
          title: "协同电子签章",
          content: "线下工作进阶为线上工作",
          src: require("@/assets/img/xtpt-ikon.png"),
        },
        {
          index: 2,
          title: "智能实测实量",
          content: "手工记录变为智能读取",
          src: require("@/assets/img/scsl-ikon.png"),
        },
      ],
      tabList: [],
      treeData: [],
      companyAndRole: [],
      showDetail: false,
      companyName: "",
      companyShortName: "",
      companyLogo: "",
      administrationFlag: false,
      groupLogo: "",
      groupName: "",
      isShow: false,
      groupList: [],
      dataList: [],
      selectId: null,
      groupData: [],
      value2: "",
      title: "创建",
      teamName: "",
      logoUrl: "",
      userId: null,
      currentGroupItem: {},
      selectGroupId: null,
      selectGroupName: "",
    };
  },
  computed: {
    judge() {
      return this.btnList.length != 0 && !window.baseconfig.pattern;
    },
    admin() {
      return sessionStorage.getItem("admin"); // 0普通用户 1超级管理员 2公司管理员
      // return 1 // 0普通用户 1超级管理员 2公司管理员 
    },
  },
  mounted() {
    console.log(
      "浏览器，操作系统=====>",
      sysTool.GetCurrentBrowser(),
      sysTool.GetOs()
    );
  },
  created() {

    const arr = Object.keys(JSON.parse(sessionStorage.getItem("groupInfo")));
    if (arr.length == 0) this.num = 10; // 用户没团队/企业，去创建

    // this.getUserGroup();
    this.refshUserGroup();
    this.checkIsAdmin();

    if (
      sessionStorage.getItem("source") == "yzl_message" ||
      sessionStorage.getItem("source") == "xtpt_message"
    ) {
      this.setup(7);
      sessionStorage.removeItem("source");
    }

    if (sessionStorage.getItem("admin") == "5") {
      this.num = 5;
    }

    console.log(this.$route.query, this.admin);
    let { logout } = this.$route.query;
    if (logout == "1") {
      this.logout();
    }
    if (this.admin == "0") {
      this.btnList = [];
    } else if (this.admin == "2") {
      this.btnList.pop();
    }
    if (sessionStorage.getItem("jumpType")) {
      this.num = 8;
      sessionStorage.removeItem("jumpType");
    }
    if (sessionStorage.getItem("editCompanyInfo")) {
      this.num = 8;
      sessionStorage.removeItem("editCompanyInfo");
      sessionStorage.removeItem("editSealImage");
    }
  },
  methods: {
    clickOpen() {
      gets(`/sys/coopSysGroup/listAllGroup/${this.userId}`).then((res) => {
        console.log(res)
        this.groupList = res.data.length > 0 ? res.data : []
        this.isShow = !this.isShow;
      })
    },
    clickSelect(item) {
      const formData = new FormData();
      formData.append("userId", this.userId);
      formData.append("groupId", item.id);
      posts('/User/changeGroup', formData).then((res) => {
        if (res.code == 200) {
          this.$message.success("切换成功！！");
          this.isShow = false
          sessionStorage.setItem("groupInfo", JSON.stringify(res.data.groupInfo))
          this.getUserGroup()
        }
        // else {
        //   this.$notify.error({
        //     title: "切换失败",
        //     message: res.msg,
        //     duration: 2000,
        //   });
        // }
      })
    },
    clickEdit(item) {
      this.title = !item ? '创建' : '修改'
      this.currentGroupItem = item
      this.teamName = !item ? '' : item.groupName
      this.logoUrl = !item ? '' : item.logoUrl

      this.num = 9
      this.isShow = false;
    },
    clickClose() {
      this.dialogOverVisible = false;
      this.groupData = []
      this.dataList = []
      this.selectGroupId = null
      this.selectGroupName = ""
      this.selectId = null
      this.value2 = ""
    },
    clickConfirm() {
      const query = {}
      query.groupId = this.selectGroupId
      query.groupName = this.selectGroupName
      query.userId = this.selectId
      const item = this.dataList.find((e) => {
        return e.id == this.selectId
      })
      query.userName = item.realname
      posts('/sys/coopSysGroup/transferGroup', query).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.$message.success("移交成功！！");
          this.clickClose()
        } else {
          this.$notify.error({
            title: "移交失败",
            message: res.msg,
            duration: 2000,
          });
        }
      })
    },
    checkIsAdmin() {
      this.administrationFlag = sessionStorage.getItem("administrationFlag") && sessionStorage.getItem("administrationFlag") == 1 ? true : false;
    },
    refshUserGroup() {
      gets('User/getLoginInfo').then(res => {
        if (res.code === 200) {
          sessionStorage.setItem("groupInfo", JSON.stringify(res.data.groupInfo))
          sessionStorage.setItem("currentUser", JSON.stringify(res.data.currentUser))
          this.getUserGroup()
        }
      })
    },
    getUserGroup() {
      const that = this;
      const userInfo = JSON.parse(sessionStorage.getItem("currentUser"));
      this.userId = userInfo.id
      const groupInfo = JSON.parse(sessionStorage.getItem("groupInfo"));
      that.groupLogo = groupInfo ? groupInfo.logoUrl : ''
      that.groupName = groupInfo ? groupInfo.groupName : ''
    },
    viewChange(index) {
      this.num = index;
    },
    logout() {
      sessionStorage.clear();
      this.$router.push("/");
      console.log("退出登录");
    },
    change(index) {
      const Base64 = require("js-base64").Base64;
      const token = sessionStorage.getItem("authorization");
      const password = sessionStorage.getItem("pwd");
      const { companyId, phone } = JSON.parse(
        sessionStorage.getItem("currentUser")
      );
      if (!this.lockState) return;
      else {
        const loading = this.$loading({
          text: "正在登录......",
          background: "rgba(0, 0, 0, 0.7)",
        });
        switch (index) {
          case 0: //优量云资料
            setTimeout(() => {
              loading.close();
              this.setLoginLogs("智能云资料");
              window.location.href = `${yzlUrl}?phone=${Base64.encode(
                phone
              )}&&companyId=${Base64.encode(companyId)}&&token=${Base64.encode(
                token
              )}}`;
            }, 1000);
            break;
          case 1: //协同平台
            setTimeout(() => {
              loading.close();
              this.setLoginLogs("智能协同平台");
              window.location.href = `${xtptUrl}?phone=${Base64.encode(
                phone
              )}&&companyId=${Base64.encode(companyId)}&&token=${Base64.encode(
                token
              )}&&password=${Base64.encode(
                  password
                )}&source=threeTerminal`;
            }, 1000);
            break;
          default:
            // //老实测实量
            // setTimeout(() => {
            //   loading.close();
            //    axios
            //     .post(scslLoginUrl, {
            //       loginName: phone,
            //       pwd: password,
            //     })
            //     .then((res) => {
            //       loading.close();
            //       console.log(res);
            //       if (res.data.Authorization) {
            //         this.setLoginLogs("智能实测实量");
            //         window.location.href = `${scslUrl}?token=${res.data.Authorization}&&user=${res.data.Data}`;
            //       } else {
            //         this.$message.error(res.data.ErrMsg);
            //       }
            //     })
            //     .catch(() => {
            //       loading.close();
            //     });
            // }, 1000);
            //新实测实量
            setTimeout(() => {
              loading.close();
              this.setLoginLogs("智能实测实量");
              window.location.href = `${scslUrl}?phone=${Base64.encode(
                phone
              )}&&token=${Base64.encode(token)}&&pwd=${Base64.encode(
                password
              )}&source=threeTerminal`;
            }, 1000);
            break;
        }
      }
    },
    setLoginLogs(type) {
      let query = {};
      query.browserType = sysTool.GetCurrentBrowser();
      query.userName = sessionStorage.getItem("loginPhone");
      query.operateSystem = sysTool.GetOs();
      query.type = type;
      posts("/User/cloudLoginRecord", query).then((res) => {
        console.log("resBBBBBBBBBCCCCCCCCCCC=====>", res);
      });
    },
    carouselChange(index) {
      console.log("轮播切换触发", index);
      this.lockState = false;
      setTimeout(() => {
        this.lockState = true;
      }, 400);
    },
    setup(index) {
      const that = this;
      that.getUserGroup();
      that.checkIsAdmin();
      if (index == 1) {
        that.num = 1;
      } else if (index == 2) {
        that.num = 6;
      } else if (index == 3) {
        get(
          `/loginInfo/initiateInvitation?companyId=${sessionStorage.getItem(
            "companyId"
          )}&inviterId=${sessionStorage.getItem("accountID")}`
        ).then((res) => {
          console.log("resBBBBBBBBBCCCCCCCCCCC=====>", res);
          if (res.code == 200) {
            that.invitationCode = res.invitationCode;
            that.dialogVisible = true;
          } else {
            that.$notify.error({
              title: "邀请码生成失败",
              message: res.msg,
              duration: 2000,
            });
          }
        });
      } else if (index == 7) {
        this.num = 7;
      } else if (index == 9) {
        this.dialogOverVisible = true;
        this.getGroupList()
      } else {
        if (this.num == 10) { // 用户没团队/企业，创建团队时点取消退出登录 
          sessionStorage.clear();
          this.$router.push("/");
          console.log("退出登录");
        }
        this.title = '创建'
        this.teamName = ''
        this.logoUrl = ''
        this.currentGroupItem = {}
        this.num = 3;
        this.refshUserGroup()
      }
    },
    copy() {
      let text = "";
      const that = this;
      const url = "https://multiport.abuildingcloud.com/";
      text =
        url +
        "\r\n邀请您加入【" +
        that.companyShortName +
        "】，请使用8位数企业邀请码【" +
        that.invitationCode +
        "】加入，务必24小时完成企业加入，其他用户请联系企业管理员处理。";
      that.$copyText(text).then(
        function (e) {
          console.log(e);
          that.$message.success("复制成功");
          that.dialogVisible = false;
        },
        function (e) {
          console.log(e);
          that.$notify.error({
            title: "复制失败",
            message: "该浏览器不支持复制,请手动复制",
            duration: 2000,
          });
        }
      );
    },
    goback() {
      this.num = 3;
    },
    getGroupList() {
      gets(`/sys/coopSysGroup/listOwnerGroup/${this.userId}`).then((res) => {
        console.log(res)
        this.groupData = res.data.length > 0 ? res.data : []
      })
    },
    selectChange(e) {
      this.selectGroupId = e
      const item = this.groupData.find((i) => {
        return i.id == e
      })
      this.selectGroupName = item.groupName
      gets(`/sys/coopSysGroup/getGroupManagerList/${e}`).then((res) => {
        console.log(res)
        this.dataList = res.data.length > 0 ? res.data : []
      })
    },
  },
};
</script>
<style lang="less">
.el-input__inner {
  color: #fff;
  background: rgba(0, 0, 0, 0);

  // width: calc(100% - 1rem);
  .el-range-separator {
    color: #fff;
  }

  .el-range-input {
    background: rgba(0, 0, 0, 0);
  }
}

.el-form-item__label {
  color: #fff;
}

.el-dialog {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #666666;
  color: #fff !important;

  .el-dialog__header {
    font-size: 16px;
    // margin-left: 1rem;
    background: rgba(0, 0, 0, 0.8) !important;
    color: #fff !important;
  }

  .el-dialog__body {
    color: #fff !important;
  }

  .el-dialog__title {
    color: #fff !important;
  }

  .el-dialog__footer {
    border: none !important;
    color: #fff !important;
  }
}

.popover-message {
  background: #092755 !important;
  border: 1px solid #092755 !important;
  padding: 0 !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-family: PingFang SC-Regular !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  right: 0 !important;
  top: 50px !important;
  width: 160px !important;
}
</style>
<style lang="less" scoped>
.dropdown-enter-active {
  max-height: 40px;
  width: 500px;
  transition: all 0.6s;
}

.dropdown-leave-active {
  max-height: 40px;
  width: 0px;
  transition: all 0.6s;
}

.title2 {
  margin: 2% 0 0 1%;
  display: flex;
  align-items: flex-end;
}

.title2 span {
  margin-left: 15px;
  font-size: 24px;
  line-height: 20px;
  vertical-align: bottom;
  letter-spacing: 15px;
}

.english {
  font-size: 14px !important;
  margin-left: 15px;
  margin-top: 10px;
  letter-spacing: 1.2px !important;
}

// .right-header {
//   position: absolute;
//   top: 40px;
//   right: 40px;
//   display: flex;
//   justify-content: space-around;
//   align-items: center;
//   font-size: 16px;
//   .test {
//     overflow: hidden;
//     display: flex;
//     justify-content: space-around;
//     align-items: center;
//     .btn-warp {
//       width: 107px;
//       height: 40px;
//       cursor: pointer;
//       border-radius: 21px;
//       text-align: center;
//       line-height: 40px;
//       transition: all 0.3s;
//       margin-right: 1rem;
//       background: rgba(24, 144, 255, 0.1);
//       .btn-item {
//         color: #d8d8d8 !important;
//       }
//     }
//     .btn-warp-active {
//       background: rgba(24, 144, 255);
//       .btn-item {
//         color: #ffffff !important;
//       }
//     }
//     .btn-warp:hover {
//       background: rgba(24, 144, 255);
//       .btn-item {
//         color: #ffffff !important;
//       }
//     }
//     .back-home {
//       min-width: 4rem;
//       cursor: pointer;
//       font-weight: 400;
//       color: #a3a3a3;
//       margin-right: 2rem;
//     }
//     .back-home:hover {
//       color: #ffffff;
//     }
//   }

//   i {
//     color: #ffffff;
//     font-size: 24px;
//     font-weight: bold;
//     cursor: pointer;
//     margin-right: 2rem;
//   }
//   i:hover {
//     color: #0080ff;
//   }
// }

.foot-warp {
  height: 100px;
  width: 100%;
  position: fixed;
  bottom: 0;

  .foot {
    margin: auto;
    text-align: center;

    span {
      margin-left: 15px;
      font-size: 24px;
      line-height: 24px;
      font-family: "Arial-Italic", Arial;
      font-style: italic;
      vertical-align: middle;
    }

    img {
      height: 16px !important;
    }
  }

  .internet-content-crovider {
    margin-top: 10px;
    font-size: 20px;
    letter-spacing: 0.5px;
    text-align: center;
  }
}

/deep/ .el-carousel__mask {
  opacity: 0 !important;
}

.ThreeTerminal {
  color: #ffffff;
  width: 100%;
  height: 100%;
  padding: 1px 0;
  background: url(../assets/img/ThreeTerminal.png) no-repeat center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background-size: cover;
  position: relative;

  .center-piece {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .title1 {
    text-align: center;
    margin: 5% auto 2%;
    font-size: 54px;
    font-weight: bold;
    color: #ffffff;
    line-height: 47px;
    letter-spacing: 6px;
    opacity: 0.6;
  }

  .title {
    text-align: center;
    margin: auto;
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
    line-height: 47px;
    letter-spacing: 6px;
    opacity: 0.6;
  }

  /deep/.el-carousel__item {
    width: 388px;
    height: 518px;
    margin: 0 45px;
    padding: 0 18px !important;
    box-sizing: border-box;
    text-align: center;
    border-radius: 20px;
    // background: url(../assets/img/xtpt-ikon.png) no-repeat center;
    background-size: 100% 100%;
    // box-shadow: inset 0px 0px 10px 0px #FFFFFF;

    .card-title {
      font-size: 32px;
      color: #ffffff;
      margin: 66px 0 12px;
    }

    .card-content {
      font-size: 20px;
      color: #abafb2;
      margin-bottom: 40px;
    }

    img {
      width: 100%;
    }
  }

  /deep/.el-carousel__item.is-active {
    // box-shadow: inset 0px 0px 10px 2px #ffffff;
  }

  // /deep/.el-carousel__item ::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 388px;
  //   height: 518px;
  //   background-image: url(../assets/img/ThreeTerminal.svg);
  //   filter: blur(12px);
  //   z-index: -1;
  //   background-size: cover;
  // }

  /deep/.el-carousel__mask {
    opacity: 0.4;
    background-color: #a3a3a3;
    // box-shadow: none;
  }

  /deep/.el-carousel {
    width: 1176px;
    margin: 4% auto;
    padding: 0 6%;
  }

  // /deep/.el-carousel__container {
  // }
}

.companys {
  width: 512px;
  height: 48px;
  background: linear-gradient(
    90deg,
    rgba(24, 144, 255, 0.1) 0%,
    rgba(24, 144, 255, 0) 100%
  );
  border-radius: 8px;
  margin: auto;
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;

  .companys-btn {
    width: 114px;
    height: 48px;
    line-height: 48px;
    background: linear-gradient(90deg, #3da4fe 0%, #2278eb 100%);
    border-radius: 8px;
  }
}

// .u-name {
//   font-size: 24px;
//   font-family: PingFang SC-Regular, PingFang SC;
//   font-weight: 400;
//   color: #FFFFFF;
// }
// .header-info {
//   padding: 24px;
//   background: #073061;
//   border-top-left-radius: 8px;
//   border-top-right-radius: 8px;
// }
// .pop-item {
//   padding: 14px 0;
// }
// .pop-item:hover {
//   background: #1890FF;
// }
.invitations-content {
  width: 542px;
  height: 56px;
  background: #34373b;
  padding: 0 12px 0 24px;
}

.invitation-btn {
  width: 110px;
  height: 32px;
  line-height: 32px;
  background: #0084ff;
}

// .el-carousel__item{
//     width: 388px;
//     margin: 0 45px;
//     text-align: center;
// }
.left-header {
  top: 40px;
  left: 40px;
}

.company-logo {
  width: 48px;
  height: 48px;
  margin-right: 16px;
}

.short-name {
  font-size: 20px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  margin-right: 16px;
}

.back-home {
  width: 80px;
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  border: 1px solid #fff;
  margin-top: 5px;
}

.el-icon-arrow-down {
  transition: all 0.4s;
  right: 16px;
  top: 9px;
  cursor: pointer;
}

.el-arrow-down-icon-active {
  transform: rotate(180deg);
  transform-origin: center center;
}

.pagedialog {
  position: absolute;
  top: 56px;
  left: 0;
  z-index: 99;
  box-shadow: 0px 0px 10px 0px rgba(39, 51, 79, 0.16);
  border-radius: 4px;

  .pageItem:first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .pageItem:last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .pageItem {
    width: 512px;
    height: 44px;
    line-height: 44px;
    background-color: #0b2e52;

    .img-add {
      width: 24px;
      height: 24px;
    }

    .page-img {
      width: 28px;
      height: 28px;
      border-radius: 4px;
      margin: 8px;
    }

    .page-name {
      display: inline-block;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #ffffff;
      flex: 0 1 438px;
    }

    .img-edit {
      width: 24px;
      height: 24px;
    }

    .pageBtn {
      width: 136px;
      text-align: center;
      height: 32px;
      line-height: 32px;
      background: #0084ff;
      border-radius: 4px;
    }
  }

  .pageItem:hover {
    background: #0e4275;

    .img-edit {
      display: block;
    }
  }

  .pageItem2:hover {
    background: #0b2e52;
  }

  .img-edit:hover {
    content: url("../assets/img/teamNew/download2.svg");
  }
}

.p1 {
  padding: 16px;
  border-bottom: 1px solid #e5e6eb;

  .p1-c {
    padding: 12px 16px 10px;
    border-radius: 4px;
    background: #1f1f1f;

    .p1-pn {
      padding: 1px 9px;
      background: #d7ebfe;
      border-radius: 2px;
      font-size: 14px;
      color: #1890ff;
    }
  }
}

.p2 {
  padding: 24px 16px 16px;
  border-bottom: 1px solid #e5e6eb;

  .p2-t {
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 16px;
  }

  .p2-c {
    height: 177px;
    border: 1px solid #393939;
    border-radius: 4px;
    overflow: auto;
    background: #1f1f1f;

    .p2-item {
      padding: 8px 16px;

      .p2-tag {
        width: 24px;
        text-align: center;
        height: 24px;
        line-height: 24px;
        background: #0080ff;
        color: #ffffff;
        border-radius: 50%;
        font-size: 12px;
      }

      .p2-name {
        font-size: 14px;
        color: #ffffff;
        margin-left: 8px;
      }
    }

    .p2-item:hover {
      background: #393939;
    }
  }
}

.dialog-footer {
  padding: 24px 32px;

  .cbtn {
    width: 88px;
    height: 32px;
    line-height: 32px;
    border-radius: 4px;
  }

  .cancel-btn {
    border: 1px solid #e8e8e8;
    margin-right: 16px;
  }

  .confirm-btn {
    color: #ffffff;
    background: #0080ff;
  }
}

/deep/.el-dialog__body {
  padding: 0;
}

/deep/.el-select-dropdown {
  position: absolute !important;
  left: 0px !important;
  top: 40px !important;
}
</style>
<style>
.black-theme {
  background: rgba(0, 0, 0, 0.8);
}
</style>