<template>
  <div>
    <div class="right-header flex items-center">
      <el-badge :value="notNumber" class="item cursor-pointer" v-if="administrationFlag" style="margin-right: 32px;">
        <img src="@/assets/img/new/message-icon.svg" @click="setup(7)" />
      </el-badge>
      <!-- 用户信息 -->
      <!-- <el-popover placement="bottom-end" width="400" trigger="hover">
        <div>123213</div>
        <div class="phone" slot="reference">
          <el-avatar v-if="imageUrl" style="margin-right: 10px" :size="32" :src="imageUrl" />
          <el-avatar v-else :size="32" style="margin-right: 10px">
            <div class="u-name w-full h-full" style="background-color: #0080ff;"
              v-if="fiveBodyId == 1 || fiveBodyId == 6">施</div>
            <div class="u-name w-full h-full" style="background-color: #ff7a00;" v-else-if="fiveBodyId == 2">建</div>
            <div class="u-name w-full h-full" style="background-color: #f2c615;" v-else-if="fiveBodyId == 3">监</div>
            <div class="u-name w-full h-full" style="background-color: #943dec;" v-else-if="fiveBodyId == 4">设</div>
            <div class="u-name w-full h-full" style="background-color: #00c14d;" v-else-if="fiveBodyId == 5">勘</div>
          </el-avatar>
        </div>
      </el-popover> -->
      <el-popover placement="bottomLeft" trigger="hover" :visible-arrow="false" popper-class="popover-message">
        <div class="phone" slot="reference">
          <el-avatar v-if="imageUrl" style="margin-right: 10px" :size="32" :src="imageUrl" />
          <el-avatar v-else :size="32" style="margin-right: 10px">
            <!-- <div class="u-name w-full h-full" style="background-color: #0080ff;"
              v-if="fiveBodyId == 1 || fiveBodyId == 6">施</div>
            <div class="u-name w-full h-full" style="background-color: #ff7a00;" v-else-if="fiveBodyId == 2">建</div>
            <div class="u-name w-full h-full" style="background-color: #f2c615;" v-else-if="fiveBodyId == 3">监</div>
            <div class="u-name w-full h-full" style="background-color: #943dec;" v-else-if="fiveBodyId == 4">设</div>
            <div class="u-name w-full h-full" style="background-color: #00c14d;" v-else-if="fiveBodyId == 5">勘</div> -->
            <div class="u-name w-full h-full" style="background-color: #0080ff;">{{ realname.substring(0, 1) }}</div>
          </el-avatar>
        </div>
        <div>
          <div>
            <div class="header-info flex items-center">
              <el-avatar v-if="imageUrl" style="margin-right: 10px" :size="32" :src="imageUrl" />
              <el-avatar v-else :size="32" style="margin-right: 10px">
                <!-- <div class="u-name w-full h-full" style="background-color: #0080ff;"
                  v-if="fiveBodyId == 1 || fiveBodyId == 6">施</div>
                <div class="u-name w-full h-full" style="background-color: #ff7a00;" v-else-if="fiveBodyId == 2">建</div>
                <div class="u-name w-full h-full" style="background-color: #f2c615;" v-else-if="fiveBodyId == 3">监</div>
                <div class="u-name w-full h-full" style="background-color: #943dec;" v-else-if="fiveBodyId == 4">设</div>
                <div class="u-name w-full h-full" style="background-color: #00c14d;" v-else-if="fiveBodyId == 5">勘</div> -->
                <div class="u-name w-full h-full" style="background-color: #0080ff;">{{ realname.substring(0, 1) }}</div>
              </el-avatar>
              <div>
                <div style="margin-bottom: 4px;">{{ realname }}</div>
                <div>{{ phone }}</div>
              </div>
            </div>
            <div class="w-full">
              <div class="w-full">
                <!-- <div v-if="administrationFlag" class="pop-item text-center cursor-pointer" @click="setup(1)">用户管理</div> -->
                <div class="pop-item text-center cursor-pointer" @click="setup(9)">团队移交</div>
                <div class="pop-item text-center cursor-pointer" @click="setup(2)">修改密码</div>
                <!-- <div class="pop-item text-center cursor-pointer" @click="setup(3)">发起邀请</div> -->
                <div class="pop-item text-center cursor-pointer" @click="logout">退出登录</div>
              </div>
            </div>
          </div>
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { get } from "@/utils/http.js";
export default {
  props: {
    administrationFlag: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      imageUrl: "",
      userInfo: "",
      realname: "",
      username: "",
      phone: "",
      // administrationFlag: false,
      notNumber: null,
      fiveBodyId: null,
    };
  },
  computed: {

  },
  mounted() {

  },
  created() {
    const userInfo = JSON.parse(sessionStorage.getItem("currentUser"))
    this.userInfo = userInfo
    this.realname = userInfo.realname
    this.phone = userInfo.phone

    // const fiveBody = JSON.parse(sessionStorage.getItem("fiveBody"))
    // this.fiveBodyId = fiveBody.id

    // this.getNotItem()
  },
  methods: {
    logout() {
      sessionStorage.clear();
      this.$router.push("/");
      console.log("退出登录");
    },
    setup(index) {
      this.$emit('setup', index)
    },
    getNotItem() {
      const query = {}
      query.companyId = sessionStorage.getItem('companyId')
      get("/loginInfo/getNotHandedOver", query).then((res) => {
        console.log(res)
        if (res.code == 200) {
          this.notNumber = res.data == 0 ? null : res.data
        } else {
          this.notNumber = null
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.right-header {
  position: absolute;
  top: 40px;
  right: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;

  .test {
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .btn-warp {
      width: 107px;
      height: 40px;
      cursor: pointer;
      border-radius: 21px;
      text-align: center;
      line-height: 40px;
      transition: all 0.3s;
      margin-right: 1rem;
      background: rgba(24, 144, 255, 0.1);

      .btn-item {
        color: #d8d8d8 !important;
      }
    }

    .btn-warp-active {
      background: rgba(24, 144, 255);

      .btn-item {
        color: #ffffff !important;
      }
    }

    .btn-warp:hover {
      background: rgba(24, 144, 255);

      .btn-item {
        color: #ffffff !important;
      }
    }

    .back-home {
      min-width: 4rem;
      cursor: pointer;
      font-weight: 400;
      color: #a3a3a3;
      margin-right: 2rem;
    }

    .back-home:hover {
      color: #ffffff;
    }
  }

  i {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 2rem;
  }

  i:hover {
    color: #0080ff;
  }
}

.u-name {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
}

.header-info {
  padding: 12px;
  background: #073061;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.pop-item {
  padding: 14px 0;
}

.pop-item:hover {
  background: #1890FF;
}
</style>