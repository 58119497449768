var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ThreeTerminal"},[_c('div',{staticClass:"right-header"},[_c('div',{staticClass:"test",class:_vm.showDetail ? 'dropdown-enter-active' : 'dropdown-leave-active'},_vm._l((_vm.btnList),function(item,index){return _c('div',{key:index,staticClass:"btn-warp",class:_vm.num == index ? 'btn-warp-active' : '',on:{"click":function($event){return _vm.viewChange(index)}}},[_c('span',{staticClass:"btn-item"},[_vm._v(_vm._s(item))])])}),0),(_vm.judge)?_c('i',{staticClass:"el-icon-setting",on:{"click":function($event){_vm.showDetail = !_vm.showDetail;
    !_vm.showDetail ? (_vm.num = 3) : '';}}}):_vm._e(),_c('i',{staticClass:"el-icon-switch-button",on:{"click":_vm.logout}})]),(_vm.num == 3)?_c('div',[_c('p',{staticClass:"title1"},[_vm._v("工程质量数智管理平台")]),_c('el-carousel',{attrs:{"autoplay":false,"trigger":"click","arrow":"never","type":"card","height":"518px","indicator-position":"none"},on:{"change":_vm.carouselChange}},_vm._l((_vm.itemList),function(item){return _c('el-carousel-item',{key:item.index,style:({
        background: `url(${item.src}) no-repeat center`,
        backgroundSize: '100% 100%',
        border: '1px solid inset #00e3ff',
        cursor: _vm.cursor,
      }),attrs:{"disabled":""},nativeOn:{"click":function($event){return _vm.change(item.index)}}},[_c('p',{staticClass:"card-title"},[_vm._v(_vm._s(item.title))]),_c('p',{staticClass:"card-content"},[_vm._v(_vm._s(item.content))])])}),1),_vm._m(0)],1):(_vm.num == 0)?_c('role-admin',{staticClass:"center-piece",attrs:{"tabList":_vm.tabList,"treeData":_vm.treeData}}):(_vm.num == 1)?_c('user-admin',{staticClass:"center-piece",attrs:{"companyAndRole":_vm.companyAndRole}}):(_vm.num == 5)?_c('admin-first'):_c('institutional-framework',{staticClass:"center-piece"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foot-warp"},[_c('div',{staticClass:"foot"},[_c('img',{attrs:{"src":require("@/assets/img/hmyl-private-deploy-logo.png")}}),_c('span',[_vm._v("www.abuildingcloud.com")])]),_c('p',{staticClass:"internet-content-crovider"},[_vm._v(" Copyright ©版权所有 豪米优量科技 浙ICP备202209088号 ")])])
}]

export { render, staticRenderFns }