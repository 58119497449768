<template>
  <div>
    <div class="bg absolute">
      <!-- <div class="title text-center">欢迎进入数智管理云平台</div> -->
      <div class="h1 text-center">修改密码</div>
      <div class="cbody">
        <div class="part">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="200px" class="demo-ruleForm">
            <el-form-item label="原始密码：" prop="oldPassword">
              <el-input v-model="ruleForm.oldPassword" placeholder="请输入原始密码" class="black-input"></el-input>
            </el-form-item>
            <el-form-item label="新密码：" prop="newPassword">
              <el-input type="password" v-model="ruleForm.newPassword" placeholder="请输入新密码" class="black-input"></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="newPassword2">
              <el-input type="password" v-model="ruleForm.newPassword2" placeholder="请再次输入新密码" class="black-input"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="part flex justify-center" style="margin: auto;">
          <div class="btn text-center cursor-pointer" style="margin-right: 40px;" @click="back">取消</div>
          <div class="btn text-center cursor-pointer" @click="change">确认修改</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from "@/utils/http.js";
export default {
  components: {  },
  data() {
    const regPass = /^[a-zA-Z0-9.]{6,20}$/
    const handleZcPasswordChange = (rule, value, callback) => {
      const password = this.ruleForm.newPassword
      if (value != password) {
        callback(new Error("请保持密码一致"));
      } else {
        if (!regPass.test(value)) {
          callback(new Error("请输入6到20个字符"));
        } else {
          callback();
        }
      }
    };
    return {
      ruleForm: {
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      },
      rules: {
        oldPassword: [
          { required: true, message: '请输入原始密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { pattern: /^[a-zA-Z0-9.]{6,20}$/, message: "请输入6到20个字符", trigger: "blur" }
        ],
        newPassword2: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { validator: handleZcPasswordChange, trigger: "blur" }
        ]
      },
    };
  },
  computed: {
    
  },
  mounted() {
    
  },
  created() {
   
  },
  methods: {
    back () {
      this.$emit('back', 100)
    },
    change () {
      const that = this
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          console.log(valid)
          const query = {}
          query.userid = sessionStorage.getItem ("accountID")
          query.oldPassword = that.ruleForm.oldPassword
          query.newPassword = that.ruleForm.newPassword
          query.affirmPassword = that.ruleForm.newPassword2
          post("/loginInfo/updatePassword", query).then((res) => {
            console.log(res)
            if (res.code == 200) {
              that.$message.success('密码修改成功')
              // that.back()
              setTimeout(() => {
                sessionStorage.clear();
                this.$router.push("/");
                console.log("退出登录");
              }, 1500)
            } else {
              that.$notify.error({
                title: '修改失败',
                message: res.msg,
                duration: 2000
              })
            }
          })
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.bg {
  width: 1148px;
  height: 699px;
  background: linear-gradient(180deg, rgba(24, 144, 255, 0.1013) 0%, rgba(24, 144, 255, 0) 100%);
  // opacity: 0.1013;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  padding: 61px 0;
}
.title {
  font-size: 36px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
}
.h1 {
  font-size: 20px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  padding-top: 11px;
  padding-bottom: 45px;
}
.cbody {
  padding: 14px 56px 0;
  background: linear-gradient(180deg, rgba(24, 144, 255, 0.1013) 0%, rgba(24, 144, 255, 0) 100%);
}
.part {
  width: 50%;
  margin-left: 20%;
  padding-top: 113px;
}
.btn {
  width: 114px;
  height: 32px;
  line-height: 32px;
  background: #0084FF;
}
</style>
<style>
.demo-ruleForm .el-form-item {
  margin-bottom: 40px;
}
.demo-ruleForm .el-form-item__label {
  color: #ffffff;
}
.black-input .el-input__inner {
  color: #ffffff;
  height: 31px;
  line-height: 31px;
  background: rgba(0, 0, 0, 0);
}
.black-select {
  width: 100%;
}
.black-select .el-input__inner {
  color: #ffffff;
  background: rgba(0, 0, 0, 0);
}
</style>