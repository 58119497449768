<template>
  <div class="ThreeTerminal">
    <!-- <div>
      <div class="title2 mt-4 ml-4">
        <img src="@/assets/img/private-deploy-logo.svg" alt="" />
        <div>
          <span>中国建筑第八工程局有限公司</span>
          <p class="english">
            CHINA CONSTRUCTION EIGHTH ENGINEERING DIVISION COLTD
          </p>
        </div>
      </div>
      <div class="right-header" @click="logout">
        <i class="el-icon-switch-button"></i>
      </div>
    </div> -->
    <!-- <p class="title1">柯桥未来医学中心项目</p> -->
    <div class="right-header">
      <div class="test" :class="showDetail ? 'dropdown-enter-active' : 'dropdown-leave-active'">
        <!-- <span class="back-home" @click="num = 3"
          >返回首页</span
        > -->
        <div v-for="(item, index) in btnList" :key="index" class="btn-warp" :class="num == index ? 'btn-warp-active' : ''"
          @click="viewChange(index)">
          <span class="btn-item">{{ item }}</span>
        </div>
      </div>
      <i class="el-icon-setting" v-if="judge" @click="
        showDetail = !showDetail;
      !showDetail ? (num = 3) : '';
      "></i>
      <i class="el-icon-switch-button" @click="logout"></i>
    </div>
    <!-- 切换产品 -->
    <div v-if="num == 3">
      <p class="title1">工程质量数智管理平台</p>
      <el-carousel :autoplay="false" trigger="click" arrow="never" type="card" height="518px" indicator-position="none"
        @change="carouselChange">
        <el-carousel-item disabled v-for="item in itemList" :key="item.index" @click.native="change(item.index)" :style="{
          background: `url(${item.src}) no-repeat center`,
          backgroundSize: '100% 100%',
          border: '1px solid inset #00e3ff',
          cursor,
        }">
          <p class="card-title">{{ item.title }}</p>
          <p class="card-content">{{ item.content }}</p>
        </el-carousel-item>
      </el-carousel>
      <div class="foot-warp">
        <div class="foot">
          <img src="@/assets/img/hmyl-private-deploy-logo.png" />
          <span>www.abuildingcloud.com</span>
        </div>
        <p class="internet-content-crovider">
          Copyright ©版权所有 豪米优量科技 浙ICP备202209088号
        </p>
      </div>
    </div>

    <!-- 角色管理 -->
    <role-admin v-else-if="num == 0" class="center-piece" :tabList="tabList" :treeData="treeData" />
    <!-- 用户管理 -->
    <user-admin v-else-if="num == 1" class="center-piece" :companyAndRole="companyAndRole" />
    <admin-first v-else-if="num == 5" />
    <!-- 组织机构 -->
    <institutional-framework v-else class="center-piece" />
  </div>
</template>

<script>
// import axios from "axios";
import { yzlUrl, xtptUrl, scslUrl } from "@/utils/services.js";
import userAdmin from "./ThreeTerminal/syUserAdmin.vue";
import roleAdmin from "./ThreeTerminal/syRoleAdmin.vue";
import institutionalFramework from "./ThreeTerminal/syInstitutionalFramework.vue";
import { posts, post, get } from "@/utils/http.js";
import * as sysTool from "@/assets/js/systemTool";
import adminFirst from "./AdminFirst.vue";
export default {
  components: { userAdmin, roleAdmin, institutionalFramework, adminFirst },
  data() {
    return {
      num: 3,
      lockState: true,
      // dialogVisible: true,
      btnList: ["角色管理", "用户管理", "组织机构"],
      value: 1,
      itemList: [
        {
          index: 0,
          title: "优量云资料",
          content: "白领资料员的标配",
          src: require("@/assets/img/yzl-ikon.png"),
        },
        {
          index: 1,
          title: "协同电子签章",
          content: "线下工作进阶为线上工作",
          src: require("@/assets/img/xtpt-ikon.png"),
        },
        {
          index: 2,
          title: "智能实测实量",
          content: "手工记录变为智能读取",
          src: require("@/assets/img/scsl-ikon.png"),
        },
      ],
      tabList: [],
      treeData: [],
      companyAndRole: [],
      showDetail: false,
    };
  },
  computed: {
    judge() {
      return this.btnList.length != "0" && !window.baseconfig.pattern;
    },
    admin() {
      return sessionStorage.getItem("admin"); // 0普通用户 1超级管理员 2公司管理员
      // return 1 // 0普通用户 1超级管理员 2公司管理员
    },
    cursor() {
      return sessionStorage.getItem("admin") == "1" ? "not-allowed" : "pointer";
    },
  },
  mounted() {
    console.log(
      "浏览器，操作系统=====>",
      sysTool.GetCurrentBrowser(),
      sysTool.GetOs()
    );
  },
  created() {
    this.queryTree();
    this.queryBodyType();
    // this.selectCompanyAndRole();
    if (sessionStorage.getItem("admin") == "5") {
      this.num = 5;
    }

    console.log(this.$route.query, this.admin);
    let { logout } = this.$route.query;
    if (logout == "1") {
      this.logout();
    }
    if (this.admin == "0") {
      this.btnList = [];
    } else if (this.admin == "2") {
      this.btnList.pop();
    }
  },
  methods: {
    // selectCompanyAndRole() {
    //   if(sessionStorage.getItem("companyId")!='undefined'){
    //     get("loginInfo/selectCompanyAndRole", {
    //     companyId: this.admin == 1 ? 0 : ~~sessionStorage.getItem("companyId"),
    //   }).then((res) => {
    //     if (res.code == 200) {
    //       this.companyAndRole = res.data;
    //     } else {
    //       this.$message.error(res.msg);
    //     }
    //   });
    //   }

    // },
    queryTree() {
      get("loginInfo/meunTree").then((res) => {
        if (res.code == 200) {
          this.treeData = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    queryBodyType() {
      post("loginInfo/queryBodyType").then((res) => {
        if (res.code == 200) {
          this.tabList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    viewChange(index) {
      this.num = index;
    },
    logout() {
      sessionStorage.clear();
      this.$router.push("/");
      console.log("退出登录");
    },
    change(index) {
      if (sessionStorage.getItem("admin") !== "1") {
        const Base64 = require("js-base64").Base64;
        const token = sessionStorage.getItem("authorization");
        // const area = sessionStorage.getItem("area");
        const password = sessionStorage.getItem("pwd");
        const { companyId, phone } = JSON.parse(
          sessionStorage.getItem("currentUser")
        );
        if (!this.lockState) return;
        else {
          const loading = this.$loading({
            text: "正在登录......",
            background: "rgba(0, 0, 0, 0.7)",
          });
          switch (index) {
            case 0: //优量云资料
              setTimeout(() => {
                loading.close();
                this.setLoginLogs("智能云资料");
                window.location.href = `${yzlUrl}?phone=${Base64.encode(
                  phone
                )}&&companyId=${Base64.encode(
                  companyId
                )}&&token=${Base64.encode(token)}}`;
              }, 1000);
              break;
            case 1: //协同平台
              loading.close();
              this.setLoginLogs("智能协同平台");
              window.location.href = `${xtptUrl}?phone=${Base64.encode(
                phone
              )}&&companyId=${Base64.encode(companyId)}&&token=${Base64.encode(
                token
              )}&source=threeTerminal`;
              break;
            default:
              //实测实量
              setTimeout(() => {
                loading.close();
                this.setLoginLogs("智能实测实量");
                window.location.href = `${scslUrl}?phone=${Base64.encode(
                  phone
                )}&&pwd=${Base64.encode(password)}&source=threeTerminal`;
              }, 1000);
              break;
          }
        }
      } else {
        this.$message.error("超级管理员仅管理系统设置");
      }
    },
    setLoginLogs(type) {
      let query = {};
      query.browserType = sysTool.GetCurrentBrowser();
      query.userName = sessionStorage.getItem("loginPhone");
      query.operateSystem = sysTool.GetOs();
      query.type = type;
      posts("/User/cloudLoginRecord", query).then((res) => {
        console.log("resBBBBBBBBBCCCCCCCCCCC=====>", res);
      });
    },
    carouselChange(index) {
      console.log("轮播切换触发", index);
      this.lockState = false;
      setTimeout(() => {
        this.lockState = true;
      }, 400);
    },
  },
};
</script>
<style lang="less">
.el-input__inner {
  color: #fff;
  background: rgba(0, 0, 0, 0);

  // width: calc(100% - 1rem);
  .el-range-separator {
    color: #fff;
  }

  .el-range-input {
    background: rgba(0, 0, 0, 0);
  }
}

.el-form-item__label {
  color: #fff;
}

.el-dialog {
  background: rgba(0, 0, 0, 0.8);
  border: 1px solid #666666;
  color: #fff !important;

  .el-dialog__header {
    font-size: 16px;
    margin-left: 1rem;
    background: rgba(0, 0, 0, 0.8) !important;
    color: #fff !important;
  }

  .el-dialog__body {
    color: #fff !important;
  }

  .el-dialog__title {
    color: #fff !important;
  }

  .el-dialog__footer {
    border: none !important;
    color: #fff !important;
  }
}
</style>
<style lang="less" scoped>
.dropdown-enter-active {
  max-height: 40px;
  width: 500px;
  transition: all 0.6s;
}

.dropdown-leave-active {
  max-height: 40px;
  width: 0px;
  transition: all 0.6s;
}

.title2 {
  margin: 2% 0 0 1%;
  display: flex;
  align-items: flex-end;
}

.title2 span {
  margin-left: 15px;
  font-size: 24px;
  line-height: 20px;
  vertical-align: bottom;
  letter-spacing: 15px;
}

.english {
  font-size: 14px !important;
  margin-left: 15px;
  margin-top: 10px;
  letter-spacing: 1.2px !important;
}

.right-header {
  position: absolute;
  top: 40px;
  right: 40px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-size: 16px;

  .test {
    overflow: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .btn-warp {
      width: 107px;
      height: 40px;
      cursor: pointer;
      border-radius: 21px;
      text-align: center;
      line-height: 40px;
      transition: all 0.3s;
      margin-right: 1rem;
      background: rgba(24, 144, 255, 0.1);

      .btn-item {
        color: #d8d8d8 !important;
      }
    }

    .btn-warp-active {
      background: rgba(24, 144, 255);

      .btn-item {
        color: #ffffff !important;
      }
    }

    .btn-warp:hover {
      background: rgba(24, 144, 255);

      .btn-item {
        color: #ffffff !important;
      }
    }

    .back-home {
      min-width: 4rem;
      cursor: pointer;
      font-weight: 400;
      color: #a3a3a3;
      margin-right: 2rem;
    }

    .back-home:hover {
      color: #ffffff;
    }
  }

  i {
    color: #ffffff;
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    margin-right: 2rem;
  }

  i:hover {
    color: #0080ff;
  }
}

.foot-warp {
  height: 100px;
  width: 100%;
  position: fixed;
  bottom: 0;

  .foot {
    margin: auto;
    text-align: center;

    span {
      margin-left: 15px;
      font-size: 24px;
      line-height: 24px;
      font-family: "Arial-Italic", Arial;
      font-style: italic;
      vertical-align: middle;
    }

    img {
      height: 16px !important;
    }
  }

  .internet-content-crovider {
    margin-top: 10px;
    font-size: 20px;
    letter-spacing: 0.5px;
    text-align: center;
  }
}

/deep/ .el-carousel__mask {
  opacity: 0 !important;
}

.ThreeTerminal {
  color: #ffffff;
  width: 100%;
  height: 100%;
  padding: 1px 0;
  background: url(../assets/img/ThreeTerminal.png) no-repeat center;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  background-size: cover;
  position: relative;

  .center-piece {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .title1 {
    text-align: center;
    margin: 5% auto 1%;
    font-size: 54px;
    font-weight: bold;
    color: #ffffff;
    line-height: 47px;
    letter-spacing: 6px;
    opacity: 0.6;
  }

  .title {
    text-align: center;
    margin: auto;
    font-size: 40px;
    font-weight: bold;
    color: #ffffff;
    line-height: 47px;
    letter-spacing: 6px;
    opacity: 0.6;
  }

  /deep/.el-carousel__item {
    width: 388px;
    height: 518px;
    margin: 0 45px;
    padding: 0 18px !important;
    box-sizing: border-box;
    text-align: center;
    border-radius: 20px;
    // background: url(../assets/img/xtpt-ikon.png) no-repeat center;
    background-size: 100% 100%;
    // box-shadow: inset 0px 0px 10px 0px #FFFFFF;

    .card-title {
      font-size: 32px;
      color: #ffffff;
      margin: 66px 0 12px;
    }

    .card-content {
      font-size: 20px;
      color: #abafb2;
      margin-bottom: 40px;
    }

    img {
      width: 100%;
    }
  }

  /deep/.el-carousel__item.is-active {
    // box-shadow: inset 0px 0px 10px 2px #ffffff;
  }

  // /deep/.el-carousel__item ::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 388px;
  //   height: 518px;
  //   background-image: url(../assets/img/ThreeTerminal.svg);
  //   filter: blur(12px);
  //   z-index: -1;
  //   background-size: cover;
  // }

  /deep/.el-carousel__mask {
    opacity: 0.4;
    background-color: #a3a3a3;
    // box-shadow: none;
  }

  /deep/.el-carousel {
    width: 1176px;
    margin: 5% auto;
    padding: 0 6%;
  }

  // /deep/.el-carousel__container {
  // }
}

// .el-carousel__item{
//     width: 388px;
//     margin: 0 45px;
//     text-align: center;
// }
</style>