<template>
  <div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="840px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="dialogClose"
    >
      <div class="warp">
        <p class="small-title">您好！{{ username }}</p>
        <p class="big-title">欢迎来到数智管理云平台</p>
        <div v-if="judge == 1" class="content flex flex-nowrap items-center">
          您将获得<span class="content-day">7</span>天试用期
          <div
            class="content-buy"
          >
            <!-- @click="$router.push({ path: '/order', query: { newUser: true } })" -->
            购买会员
          </div>
        </div>
        <div v-if="judge == 3" class="content">
          <p style="magin-bottom: 0px !important">
            您的版本将于<span class="content-day red-color">{{ day }}</span
            >天后到期
          </p>
          <p>
            过期后数据将保存<span class="content-day red-color">15</span
            >天，请及时续费
          </p>
        </div>
        <div v-if="judge == 4" class="content">
          您的版本已过期，请续费后再进行使用
        </div>
        <div class="btn-warp">
          <div class="text-center btn-list">
            <el-button
              v-if="judge == 3"
              size="small"
              style="width: 200px"
              plain
              @click="dialogVisible = false"
            >
              稍后再说
            </el-button>
            <el-button
              type="primary"
              size="small"
              :style="{ width: judge == 3 ? '200px' : '262px' }"
              @click="confirm"
            >
              {{
                judge == 1 ? "立即体验" : judge == 2 ? "立即进入" : "立即续费"
              }}
            </el-button>
          </div>
          <div v-if="judge != 4" class="text-center">
            <el-checkbox v-model="checked"
              ><span>不再弹出欢迎界面</span></el-checkbox
            >
          </div>
        </div>
        <i
          class="el-icon-circle-close"
          v-if="judge != 4"
          @click="dialogVisible = false"
        ></i>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { gets } from "@/utils/http";
// import path from "path";
export default {
  data() {
    return {
      day: 7,
      judge: 3, // 1：新用户第一次进来（7天试用期提醒） 2：正常提醒  3：到期提醒  4：续费提醒（无关闭按钮）
      firstpopup: 0,
      checked: false,
      dialogVisible: false,
      username: JSON.parse(sessionStorage.getItem('currentUser')).realname || "",
      imgList: [
        require("@/assets/img/newUser/new-alert-1.svg"),
        require("@/assets/img/newUser/new-alert-2.svg"),
        require("@/assets/img/newUser/new-alert-3.svg"),
        require("@/assets/img/newUser/new-alert-4.svg"),
        require("@/assets/img/newUser/new-alert-5.svg"),
        require("@/assets/img/newUser/new-alert-6.svg"),
        require("@/assets/img/newUser/new-alert-7.svg"),
        require("@/assets/img/newUser/new-alert-8.svg"),
      ],
    };
  },
  mounted() {
    // this.init();
  },
  methods: {
    next() {
      this.dialogVisible = false;
      // this.$emit("NextNoviceGuide");
    },
    init() {
      gets("Authorization/companyExpire", {
        companyId: sessionStorage.getItem("companyId"),
      }).then((res) => {
        const that = this;
        const {
          code,
          data: { remainingDays, firstpopup, nopopup, status },
        } = res;
        if (code == 200) {
          that.day = remainingDays;
          // 第一次打开
          if (firstpopup == 1) {
            that.firstpopup = firstpopup; //为1是调closeTip接口
            that.judge = 1;
            that.dialogVisible = true;
          } else {
            that.firstpopup = 0;
            // 显示对应的界面
            if (nopopup == 1 || status == 0) {
              switch (status) {
                case 0:
                  that.judge = 4;
                  break;
                case 1:
                  that.judge = 2;
                  break;
                case 2:
                  that.judge = 3;
                  break;
              }
              that.dialogVisible = true;
            }
          }
        } else {
          that.$message.error("接口异常");
        }
      });
    },
    dialogClose() {
      if (this.checked) {
        gets("User/permanentlyClosedTip", {
          companyId: sessionStorage.getItem("companyId"),
        })
      }
      if(this.firstpopup==1){
        gets("User/closeTip", {
          companyId: sessionStorage.getItem("companyId"),
        })
      }
    },
    confirm() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-dialog__header {
  display: none;
}

/deep/.el-dialog__body {
  padding: 0 !important;
  overflow: hidden;
  border-radius: 8px;
  height: 516px !important;
}

.warp {
  width: 840px;
  height: 516px;
  border-radius: 8px;
  padding: 100px 95px 0;
  box-sizing: border-box !important;
  background: url("@/assets/img/newUser/new-user-alert.svg") no-repeat center;
  .el-icon-circle-close {
    position: absolute;
    top: 0;
    color: #fff;
    font-size: 40px;
    right: -50px;
    cursor: pointer;
    transition: all 0.5s;
  }
  .el-icon-circle-close:hover {
    rotate: 90deg;
  }
  .small-title {
    margin-bottom: 14px;
    font-size: 14px;
    color: #5c6066;
    line-height: 14px;
    letter-spacing: 2px;
  }

  .big-title {
    font-size: 26px;
    color: #2e3033;
    line-height: 30px;
    letter-spacing: 2px;
    margin-bottom: 18px;
  }

  .content {
    font-size: 18px;
    line-height: 42px;
    color: #707070;
    p {
      margin-bottom: 0px;
    }

    .content-day {
      margin: 0 0.5rem;
      font-size: 28px;
      font-weight: bold;
      color: #0080ff;
    }

    .red-color {
      color: red !important;
    }

    .content-buy {
      height: 24px;
      display: inline;
      font-size: 14px;
      cursor: pointer;
      padding: 0 0.3rem;
      line-height: 24px;
      margin-left: 1rem;
      color: #0080ff;
      border-radius: 4px;
      border: #0080ff 1px solid;
    }
  }

  .btn-warp {
    top: 396px;
    width: calc(100% - 190px);
    position: absolute;

    .btn-list {
      margin-bottom: 12px;
    }
  }
}
</style>