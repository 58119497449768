<template>
  <div>
    <div class="bg absolute">
      <!-- <div class="title text-center">欢迎进入数智管理云平台</div> -->
      <div class="h1 text-center">{{ title }}团队（企业）</div>
      <div class="cbody">
        <!-- <div class="part3-picture text-center">
          <el-upload 
            class="zxcsdfaffasf" 
            action="#" 
            multiple
            :show-file-list="false" 
            :http-request="httpRequest"
            accept=".jpg,.png,.svg,.jpeg">
            <div class="logo-has flex items-center mr-4" v-if="logo2Url">
              <img class="logo-img" :src="logo2Url" />
            </div>
            <div class="logo-no relative" v-else>
              <i class="el-icon-plus"></i>
              <span class="el-logo">添加logo</span>
            </div>
          </el-upload>
        </div> -->
        <div class="part3-input">
          <div class="part3Desc">请输入团队名称（企业名称）</div>
          <div>
            <!-- <el-input class="part3Input" v-model="team2Name" placeholder="团队名称（企业名称）"></el-input> -->
            <div class="teamBody flex items-center justify-between">
            <el-input class="teamInput" v-model="team2Name" placeholder="团队名称（企业名称）"></el-input>
            <el-upload class="teamUpload" action="#" multiple :show-file-list="false" :http-request="httpRequest"
              accept=".jpg,.png,.svg,.jpeg">
              <div class="logo-has flex items-center mr-4" v-if="logo2Url">
                <span class="logo-nadd invisible">重新添加</span>
                <img class="logo-img" :src="logo2Url" />
              </div>
              <span v-else>添加logo</span>
            </el-upload>
          </div>
          </div>
        </div>
        <div class="part flex justify-center" style="margin: auto;">
          <div class="btn text-center cursor-pointer" style="margin-right: 40px;" @click="back">取消</div>
          <div class="btn text-center cursor-pointer" @click="clickConfirm">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { posts } from "@/utils/http.js";
export default {
  props: {
    title: { type: String, default: "" },
    teamName: { type: String, default: "" },
    logoUrl: { type: String, default: "" },
    currentGroupItem: { type: Object, default: () => { } }
  },
  components: {},
  data() {
    return {
      fileList: [],
      hideUpload: false,
      editState: false,
      team2Name: this.teamName,
      logoFile: '',
      logo2Url: this.logoUrl,
    };
  },
  computed: {

  },
  mounted() {

  },
  created() {

  },
  methods: {
    back() {
      this.$emit('back', 100)
    },
    onChange(file, fileList) {
      this.fileList1 = fileList;
      this.aafile1 = file.raw;
      this.hideUpload1 = true
      // this.uploadImg('businesslicense')
      console.log("onChange", file, fileList);
    },
    httpRequest(file) {
      console.log("httpRequest", file, this.fileList);
      this.logoFile = file.file;
      this.logo2Url = URL.createObjectURL(file.file);
    },
    handleRemove(file, fileList) {
      this.fileList1 = fileList;
      this.aafile1 = ''
      this.url = ''
      this.jpgPath = ''
      setTimeout(() => {
        this.hideUpload1 = false
      }, 200)
    },
    clickConfirm() {
      if (this.team2Name == '') {
        this.$message.error('团队名称（企业名称不能为空）！！')
        return false
      }
      this.isLoad = true;
      if (this.title == '创建') {
        const formData = new FormData();
        if (this.logoFile) formData.append("file", this.logoFile);
        formData.append("groupName", this.team2Name);
        posts('/sys/coopSysGroup/saveGroup', formData).then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.isLoad = false
            this.$message.success("创建成功！！");
            this.$emit('back', 100)
          } else {
            this.isLoad = false;
            this.$notify.error({
              title: "创建失败",
              message: res.msg,
              duration: 2000,
            });
          }
        })
      } else {
        const formData = new FormData();
        if (this.logoFile) formData.append("file", this.logoFile);
        formData.append("groupId", this.currentGroupItem.id);
        formData.append("groupName", this.team2Name);
        posts('/sys/coopSysGroup/updateGroup', formData).then((res) => {
          console.log(res)
          if (res.code == 200) {
            this.isLoad = false
            this.$message.success("修改成功！！");
            this.$emit('back', 100)
          } else {
            this.isLoad = false
            this.$notify.error({
              title: "修改失败",
              message: res.msg,
              duration: 2000,
            });
          }
        })
      }

    },
  },
};
</script>
<style lang="less" scoped>
.teamBody {
  width: 100%;
  height: 64px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  padding-right: 16px;
  .teamInput {
    width: 70%;
    /deep/.el-input__inner {
      border: none;
      color: #fff;
      padding: 0 0 0 16px;
      background: rgba(0, 0, 0, 0);
    }
  }
  .teamUpload {
    /deep/span {
      color: #0080ff;
      padding: 0 16px 0 0;
    }
    .logo-has:hover {
      .logo-nadd {
        visibility: visible;
      }
    }
    .logo-img {
      width: 40px;
      height: 40px;
    }
  }
}
.bg {
  width: 1148px;
  height: 699px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(180deg, rgba(24, 144, 255, 0.1013) 0%, rgba(24, 144, 255, 0) 100%);
  border-radius: 8px;
}

.h1 {
  font-size: 36px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  padding-top: 60px;
}

.cbody {
  padding: 60px 56px 0;
}

.part {
  width: 50%;
  margin-left: 20%;
  padding-top: 113px;
}

.part3-picture {
  margin-bottom: 44px;
}

.btn {
  width: 114px;
  height: 32px;
  line-height: 32px;
  background: #0084FF;
}

.el-icon-plus {
  font-size: 48px;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.el-logo {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.part3-input {
  width: 552px;
  margin: auto;

  .part3Desc {
    font-size: 14px;
    color: #ffffff;
    margin-bottom: 24px;
  }

  .part3Input {
    /deep/.el-input__inner {
      background-color: #123E6A;
    }
  }
}

.logo-img {
  width: 148px;
  height: 148px;
}
.logo-no {
  width: 148px; 
  height: 148px;
  border: 1px dashed #FFFFFF;
  border-radius: 4px;
}
</style>
<style>.zxcsdfaffasf .el-upload--picture-card {
  background-color: transparent;
}</style>