<template>
  <div class="role-warp">
    <div class="h-full">
      <div class="flex items-center justify-between" style="margin-bottom: 2.4vh;">
        <div class="part1-item flex items-center">
          <img src="@/assets/img/new/register-add-icon02.svg" style="width: 3.65vw;margin-left: 4vw;" />
          <div class="btn cursor-pointer" @click="toCreate">创建新企业</div>
        </div>
        <div class="part1-item flex items-center">
          <img src="@/assets/img/new/register-join-icon02.svg" style="width: 3.65vw;margin-left: 4vw;" />
          <div class="btn cursor-pointer" @click="toJoin">加入新企业</div>
        </div>
      </div>
      <div class="part2" style="height: calc(100% - 176px);">
        <div class="part2-content h-full flex justify-between flex-wrap content-start" :ref="`mokuai`">
          <div
            v-for="(item, index) in companyList"
            :key="index" class="part2-item cursor-pointer"
            :class="{'part2-selected': item.selected, 'invisible': !item.showed, 'part2-my-create': item.status == 1 }"
            :style="`width: ${itemCompanyWidth}px`"
            @click="selectCompany(index, item)">
            <div class="part2-body relative flex flex-col items-center justify-center">
              <div v-if="item.status == 1" class="bjb cursor-pointer absolute" @click.stop="editCompany(item.id)"><img /></div>
              <div class="tct cursor-pointer absolute" @click.stop="logoutCompany(item.id, item.roleId)"><img /></div>
              <img v-if="item.companyLogopath" :src="item.companyLogopath" style="width: 2.5vw;height: 2.5vw;"  />
              <img v-else src="@/assets/img/new/logo-icon.svg" style="width: 2.5vw;height: 2.5vw;" />
              <div class="part2-companyname">{{ item.companyName }}</div>
              <div class="part2-status text-center" v-if="item.status == 1">我创建的</div>
              <div class="part2-status text-center" v-else>我加入的</div>
              <img class="selected-img absolute" v-if="item.selected" src="@/assets/img/new/company-selected.svg" />
            </div>
          </div>
        </div>
        <div class="flex justify-center">
          <div class="part2-btn text-center cursor-pointer" @click="goback">返回</div>
          <div class="part2-btn text-center cursor-pointer" style="margin-left: 40px;" @click="changeCompany">确定</div>
        </div> 
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="618px"
      append-to-body
      custom-class="black-theme"
      title="选择移交对象"
      :modal="false"
      :close-on-click-modal="false"
      destroy-on-close
      :before-close="exitCancel"
    >
      <div>
        <div class="invitation-content">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="用户角色：" prop="role">
              <!-- <el-select
                v-model="ruleForm.role"
                placeholder="请选择用户角色"
                class="black-select"
                :disabled="true"
              >
                <el-option v-for="item in tabList2" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select> -->
              <el-input v-model="ruleForm.role" placeholder="请选择用户角色" :disabled="true" class="black22-input22"></el-input>
            </el-form-item>
            <el-form-item label="用户名称：" prop="id">
              <el-select
                v-model="ruleForm.id"
                placeholder="请选择"
                class="black-select"
                @change="selectChange"
              >
                <el-option v-for="item in tabList3" :key="item.id" :value="item.id" :label="item.realname"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户账号：" prop="accountName">
              <!-- <el-input
                v-model="ruleForm.accountName"
                placeholder="请选择"
                class="black-select"
                :disabled="true"
              >
                <el-option v-for="item in tabList4" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-input> -->
              <el-input v-model="ruleForm.accountName" placeholder="请输入用户账号" :disabled="true" class="black22-input22"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="flex items-center justify-center">
          <div class="dialog-btn btn1 text-center cursor-pointer" @click="exitCancel">取消</div>
          <div class="dialog-btn btn2 text-center cursor-pointer" @click="exitTo">移交并退出</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { post, get } from "@/utils/http.js";
export default {
  components: {  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        role: '',
        id: undefined,
        realName: '',
        accountName: ''
      },
      rules: {
        id: [
          { required: true, message: "请选择用户名称", trigger: "blur", type: "number" },
        ]
      },
      tabList2: [],
      tabList3: [
        {
          id: 1,
          name: 'aaa'
        },
        {
          id: 2,
          name: 'nnn'
        }
      ],
      tabList4: [],
      createList: [],
      joinList: [],
      companyList: [
        {
          id: 1,
          companyName: '浙江xxx集团有限公司',
          status: 1,
          selected: false
        },
        {
          id: 2,
          companyName: '浙江xxx集团有限公司',
          status: 2,
          selected: false
        },
        {
          id: 3,
          companyName: '浙江xxx集团有限公司',
          status: 2,
          selected: false
        },
        {
          id: 4,
          companyName: '浙江xxx集团有限公司',
          status: 1,
          selected: false
        },
        {
          id: 5,
          companyName: '浙江xxx集团有限公司',
          status: 1,
          selected: false
        },
        {
          id: 6,
          companyName: '浙江xxx集团有限公司',
          status: 1,
          selected: false
        },
      ],
      currentCompany: {},
      exitCompanyId: null,
      exitCompanyRoleName: '',
      itemCompanyWidth: 0
    };
  },
  computed: {
    
  },
  mounted() {
    this.$nextTick(function () {
      setTimeout(() => {
        const mainBarHeight = this.$refs[`mokuai`].offsetWidth - 32 - 16
        if ((mainBarHeight / 3) < 372 && (mainBarHeight / 3) > 240 ) {
          this.itemCompanyWidth = (mainBarHeight / 3) - 32
        }
        if ((mainBarHeight / 3) <= 240 ) {
          this.itemCompanyWidth = 240
        }
        if ((mainBarHeight / 3) >= 372) {
          if ((mainBarHeight / 4) <= 372) {
            this.itemCompanyWidth = 330
          } else {
            this.itemCompanyWidth = 340
          }
        }
        console.log('mainBarHeight==========>', mainBarHeight)
      }, 200)
    })
  },
  created() {
    setTimeout(() => {
      this.getCompanyList()
    }, 200)
    
    this.currentCompany.id = JSON.parse(sessionStorage.getItem("currentUser")).companyId
  },
  methods: {
    getCompanyList () {
      const that = this
      // that.companyList = []
      const query = {}
      query.userid = sessionStorage.getItem("accountID")
      get("/loginInfo/myCreateandjoin", query).then((res) => {
        console.log(res)
        const create = res.data.create
        create.forEach((item) => {
          if (item.id == JSON.parse(sessionStorage.getItem("currentUser")).companyId) {
            item.status = 1
            item.selected = true
          } else {
            item.status = 1
            item.selected = false
          }
          item.showed = true
        })
        const join = res.data.join
        join.forEach((item) => {
          if (item.id == JSON.parse(sessionStorage.getItem("currentUser")).companyId) {
            item.status = 2
            item.selected = true
          } else {
            item.status = 2
            item.selected = false
          }
          item.showed = true
        })
        that.createList = create
        that.joinList = join
        const companyList = create.concat(join)
        const num = (companyList.length) % 4
        console.log('num==============>', num)
        if (num == 2) {
          companyList.push({showed: false})
        }
        console.log('companyList==============>', companyList)
        that.companyList = companyList
      })
    },
    toCreate () {
      sessionStorage.setItem("jumpType", 1);
      this.$router.push({
        path: "RouteCompany",
        // query: { type: 1 },
      });
    },
    toJoin () {
      sessionStorage.setItem("jumpType", 2);
      this.$router.push({
        path: "RouteCompany",
        // query: { type: 2 },
      });
    },
    selectCompany (index) {
      const that = this
      that.currentCompany = []
      var companyList = that.companyList
      companyList.forEach((e) => {
        e.selected = false
      })
      companyList[index].selected = true
      that.currentCompany = companyList[index]
      that.companyList = companyList
    },
    editCompany (id) {
      console.log('editCompany接口进来了');
      const that = this
      const query = {}
      query.companyId = id
      get("/loginInfo/getCompayInfo", query).then((res) => {
        console.log('=========================>',res)
        if (res.code == 200) {
          console.log('=========================>200');
          // debugger()
          // localStorage.setItem('editCompanyInfo',JSON.stringify(res.data.companyInfo))
          // localStorage.setItem('editSealImage',JSON.stringify(res.data.SealImage))
          sessionStorage.setItem("editCompanyInfo", JSON.stringify(res.data.companyInfo))
          sessionStorage.setItem("editSealImage", JSON.stringify(res.data.sealImage))
          console.log('=========================>ok');
          that.$router.push({
            path: "RouteCompany",
            // query: { type: 2 },
          });
        } else {
          that.$notify.error({
            title: '查询失败',
            message: res.msg,
            duration: 2000
          })
        }
      })
    },
    logoutCompany (id, roleId) {
      const that = this
      that.exitCompanyId = id
      const title = "退出企业";
      const content = "退出企业前，请先移交数据权限";
      that.$confirm(content, title, {
        customClass: "customClass",
        confirmButtonText: '立即移交',
        cancelButtonText: '直接退出',
        type: 'warning',
        distinguishCancelAndClose: true,
      }).then(() => {
        var tabList3 = []
        const query = {}
        query.roleId = roleId
        query.userId = sessionStorage.getItem("accountID")
        get("/loginInfo/getDevolvedUsers", query).then((res) => {
          console.log('=========================>',res)
          that.ruleForm.role = res.data.roleInfo.name
          that.exitCompanyRoleName = res.data.roleInfo.name
          let users = res.data.users
          for (var i = 0; i < users.length; i++) {
            tabList3[i] = {}
            tabList3[i].id = users[i].id
            tabList3[i].realname = users[i].realname
            tabList3[i].username = users[i].username
          }
          that.tabList3 = tabList3
          that.dialogVisible =- true
          console.log('that.tabList3======>', that.tabList3)
        })
      }).catch((action) => {
        if (action == 'cancel') {
          const query1 = {}
          query1.roleId = roleId
          query1.userId = sessionStorage.getItem("accountID")
          get("/loginInfo/getDevolvedUsers", query1).then((res) => {
            console.log('=========================>',res)
            that.exitCompanyRoleName = res.data.roleInfo.name
            const query = {}
            query.companyId = that.exitCompanyId
            query.devolvedRealname = JSON.parse(sessionStorage.getItem("currentUser")).realname
            query.devolvedUserRoleId = JSON.parse(sessionStorage.getItem("currentUser")).roleId
            query.devolvedUserid = JSON.parse(sessionStorage.getItem("currentUser")).id
            query.devolvedUsername = JSON.parse(sessionStorage.getItem("currentUser")).username
          
            query.handoverInfo = "【" + that.exitCompanyRoleName + "】" + JSON.parse(sessionStorage.getItem("currentUser")).realname + "，退出企业团队"
            
            post("/loginInfo/quitCompany", query).then((res) => {
              console.log(res)
              if (res.code == 200) {
                that.$message.success('退出成功!!')
                setTimeout(() => {
                  sessionStorage.clear();
                  that.$router.push("/");
                }, 1000)
              } else {
                that.$notify.error({
                  title: '退出失败',
                  message: res.msg,
                  duration: 2000
                })
              }
            })
          })
        } else {
          return false;
        }
      })
      // that.$alert(content, title, {
      //   customClass: "customClass",
      //   showCancelButton: true,
      //   confirmButtonText: "立即移交",
      //   cancelButtonText: "直接退出",
      //   type: 'warning',
      //   callback: (action) => {
      //     if (action == "confirm") {
      //       var tabList3 = []
      //       const query = {}
      //       query.roleId = roleId
      //       query.userId = sessionStorage.getItem("accountID")
      //       get("/loginInfo/getDevolvedUsers", query).then((res) => {
      //         console.log('=========================>',res)
      //         that.ruleForm.role = res.data.roleInfo.name
      //         that.exitCompanyRoleName = res.data.roleInfo.name
      //         let users = res.data.users
      //         for (var i = 0; i < users.length; i++) {
      //           tabList3[i] = {}
      //           tabList3[i].id = users[i].id
      //           tabList3[i].realname = users[i].realname
      //           tabList3[i].username = users[i].username
      //         }
      //         that.tabList3 = tabList3
      //         that.dialogVisible =- true
      //         console.log('that.tabList3======>', that.tabList3)
      //       })
            
      //     } else {
      //       const query1 = {}
      //       query1.roleId = roleId
      //       query1.userId = sessionStorage.getItem("accountID")
      //       get("/loginInfo/getDevolvedUsers", query1).then((res) => {
      //         console.log('=========================>',res)
      //         that.exitCompanyRoleName = res.data.roleInfo.name
      //         const query = {}
      //         query.companyId = that.exitCompanyId
      //         query.devolvedRealname = JSON.parse(sessionStorage.getItem("currentUser")).realname
      //         query.devolvedUserRoleId = JSON.parse(sessionStorage.getItem("currentUser")).roleId
      //         query.devolvedUserid = JSON.parse(sessionStorage.getItem("currentUser")).id
      //         query.devolvedUsername = JSON.parse(sessionStorage.getItem("currentUser")).username
          
      //         query.handoverInfo = "【" + that.exitCompanyRoleName + "】" + JSON.parse(sessionStorage.getItem("currentUser")).realname + "，退出企业团队"
            
      //         post("/loginInfo/quitCompany", query).then((res) => {
      //           console.log(res)
      //           if (res.code == 200) {
      //             that.$message.success('退出成功!!')
      //             setTimeout(() => {
      //               sessionStorage.clear();
      //               that.$router.push("/");
      //             }, 1000)
      //           } else {
      //             that.$notify.error({
      //               title: '退出失败',
      //               message: res.msg
      //             })
      //           }
      //         })
      //       })
            
      //     }
      //   }
      // });
    },
    exitCancel () {
      this.$refs.ruleForm.resetFields()
      this.dialogVisible = false
    },
    exitTo () {
      const that = this
      that.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const query = {}
          query.companyId = that.exitCompanyId
          query.devolvedRealname = JSON.parse(sessionStorage.getItem("currentUser")).realname
          query.devolvedUserRoleId = JSON.parse(sessionStorage.getItem("currentUser")).roleId
          query.devolvedUserid = JSON.parse(sessionStorage.getItem("currentUser")).id
          query.devolvedUsername = JSON.parse(sessionStorage.getItem("currentUser")).username
          
          query.handoverInfo = "【" + that.exitCompanyRoleName + "】" + JSON.parse(sessionStorage.getItem("currentUser")).realname + "，退出企业团队"
          query.handoverRealname = that.ruleForm.realName
          query.handoverUserid = that.ruleForm.id
          query.handoverUsername = that.ruleForm.accountName
          post("/loginInfo/saveDevolvedAndQuit", query).then((res) => {
            console.log(res)
            if (res.code == 200) {
              that.$message.success('移交成功!!')
              setTimeout(() => {
                sessionStorage.clear();
                that.$router.push("/");
              }, 1000)
            } else {
              that.$notify.error({
                title: '移交失败',
                message: res.msg,
                duration: 2000
              })
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      })
    },
    changeCompany () {
      const that = this
      if (that.currentCompany.id == JSON.parse(sessionStorage.getItem("currentUser")).companyId) {
        // const title = "系统提示";
        // const content = "该公司已是当前企业";
        // that.$alert(content, title, {
        //   customClass: "customClass",
        //   showCancelButton: false,
        //   confirmButtonText: "确定",
        //   type: 'warning',
        //   callback: (action) => {
        //     if (action == "confirm") {
        //       return false 
        //     } else {
        //       return false 
        //     }
        //   },
        // })
        // return false
      }
      post("/loginInfo/changeCompany?userid=" + sessionStorage.getItem("accountID") + "&companyId=" + that.currentCompany.id).then((res) => {
        console.log(res)
        if (res.code == 200) {
          sessionStorage.setItem('companyId', res.companyInfo.id)
          sessionStorage.setItem("currentUser", JSON.stringify(res.currentUser))
          sessionStorage.setItem('fiveBody', JSON.stringify(res.fiveBody))
          sessionStorage.setItem('companyInfo', JSON.stringify(res.companyInfo))
          that.$message.success('切换企业成功！！')
          that.$emit('back')
        } else {
          that.$notify.error({
            title: '切换企业失败',
            message: res.msg,
            duration: 2000
          })
        }
      })
    },
    selectChange (e) {
      console.log(e)
      const idx = this.tabList3.findIndex(function (item) {
        return item.id == e
      })
      this.ruleForm.accountName = this.tabList3[idx].username
      this.ruleForm.realName = this.tabList3[idx].realname
    },
    goback () {
      this.$emit('back')
    }
  },
};
</script>
<style lang="less" scoped>
.role-warp {
  width: 60vw;
  height: 75vh;
  color: #fff;
  position: absolute;
}
.part1-item {
  width: 28.5vw;
  padding: 2.4vh 0;
  background: linear-gradient(180deg, rgba(24, 144, 255, 0.1) 0%, rgba(24, 144, 255, 0.1) 100%);
  border-radius: 8px;
}
.btn {
  width: 12.6vw;
  text-align: center;
  padding: 1.2vh 0;
  background: linear-gradient(90deg, #3DA4FE 0%, #2278EB 100%);
  border-radius: 8px;
  font-size: 0.7vw;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-left: 2.9vw;
}
.part2-content {
  height: calc(100% - 75px);
  padding: 0 16px;
  margin-bottom: 16px;
  background: linear-gradient(180deg, rgba(24, 144, 255, 0.1) 0%, rgba(24,144,255,0) 100%);
  overflow-y: auto;
}
.part2-btn {
  width: 114px;
  height: 32px;
  line-height: 32px;
  background: #0084FF;
  border-radius: 4px;
}
.part2-item {
  width: 340px;
  height: 214px;
  margin: 16px;
  border-radius: 8px;
}
.part2-selected {
  border: 2px solid #2780EF;
  border-radius: 8px;
}
.part2-body {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(24, 144, 255, 0.1) 0%, rgba(24, 144, 255, 0.1) 100%);
  
}
.part2-selected {
  .part2-body:hover {
    .bjb {
      img {
        content: url("../../assets/img/new/company-edit.svg");
      }
    }
    .tct {
      img {
        content: url("../../assets/img/new/company-logout.svg");
      }
    }
  }
}
// .part2-my-create {
//   .part2-body:hover {
//     .bjb {
//       img {
//         content: url("../../assets/img/new/company-edit.svg");
//       }
//     }
//     .tct {
//       img {
//         content: url("../../assets/img/new/company-logout.svg");
//       }
//     }
//   }
// }
.bjb {
  top: 0.625vw;
  right: 2.1875vw;
}
.tct {
  top: 0.625vw;
  right: 0.625vw;
}
.part2-content:after{
  content: "";
  // width: 372px;
  width: 340px;
  height: 0;
  visibility: hidden;
  margin: 16px;
}
.part2-companyname {
  font-size: 0.7vw;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-top: 2.2vh;
}
.part2-status {
  padding: 0.3vh 0.32vw;
  border-radius: 10px;
  border: 1px solid #1890FF;
  font-size: 0.4vw;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1890FF;
  margin-top: 0.6vh;
}
.selected-img {
  width: 1.25vw;
  bottom: 0.625vw;
  right: 0.625vw;
}
.invitation-content {
  width: 80%;
  margin-left: 10%;
}
.dialog-btn {
  width: 114px;
  height: 32px;
  line-height: 32px; 
}
.btn1 {
  border: 1px solid #1890FF;
  margin-right: 40px;
}
.btn2 {
  background: #0084FF;
}
</style>