import Vue from 'vue'
import VueRouter from 'vue-router'
import ThreeTerminal from '@/views/ThreeTerminal.vue'
import SYthreeTerminal from '@/views/SYthreeTerminal.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};
const pattern = window.baseconfig.pattern

const routerPC = [
  {
    path: '/',
    name: 'LoginIndex',
    component:  () => import('../views/LoginIndex.vue')
  },
  {
    path: '/ThreeTerminal',
    name: 'ThreeTerminal',
    component: pattern ? ThreeTerminal : SYthreeTerminal
  },
  {
    path: '/redirectView',
    name: 'redirectView',
    component: () => import('../views/redirectView.vue')
  },
  {
    path: '/RouteCompany',
    name: 'RouteCompany',
    component: () => import('../views/RouteCompany.vue')
  },
  {
    path: '/DDredirect_uri',
    name: 'DDredirect_uri',
    meta: { DontIntercept: true },
    component: () => import('../views/redirect/DDredirect.vue')
  },
  {
    path: '/redirect_url',
    name: 'redirect_url',
    component: () => import('../views/redirect_url/MMredirect.vue'),
    meta: { DontIntercept: true },
  },
  {
    path: '/invite',
    name: 'invite',
    component: () => import('../views/invite/inviteUser.vue'),
    meta: { DontIntercept: true },
  }
]
const routerM = [
  {
    path: "/",
    name: "",
    redirect: "/login",
    component: () =>
      import("../phoneViews/Login/index.vue"),                     //登录页
    children:[
      {
        path: "/login",
        name: "Login",
        meta: { DontIntercept: true },
        component: () =>
          import("../phoneViews/Login/login.vue"),         
      },
      {
        path: "/note",
        name: "Note",
        meta: { DontIntercept: true },
        component: () =>
          import("../phoneViews/Login/note.vue"),         
      },
      {
        path: "/register",
        name: "Register",
        meta: { DontIntercept: true },
        component: () =>
          import("../phoneViews/Login/registerUser.vue"),         
      },
      {
        path: "/registerInfo",
        name: "RegisterInfo",
        meta: { DontIntercept: true },
        component: () =>
          import("../phoneViews/Login/registerInfo.vue"),         
      },
      {
        path: "/registerPassword",
        name: "RegisterPassword",
        meta: { DontIntercept: true },
        component: () =>
          import("../phoneViews/Login/registerPassword.vue"),         
      },
      {
        path: "/forgetTel",
        name: "ForgetTel",
        meta: { DontIntercept: true },
        component: () =>
          import("../phoneViews/Login/forgetTel.vue"),         
      },{
        path: "/forgetPass",
        name: "ForgetPass",
        meta: { DontIntercept: true },
        component: () =>
          import("../phoneViews/Login/forgetPass.vue"),         
      },
    ]
  },
  {
    path: "/invite",
    name: "Invite",
    meta: { DontIntercept: true },
    component: () =>
      import("../phoneViews/Invite/invite.vue"),           //邀请成员
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("../phoneViews/Home/home.vue"),                       //主页
  },
]
const ISMOBILE = function () {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
return flag;
};
console.log(ISMOBILE())
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: ISMOBILE() ? routerM : routerPC,
})

export default router
