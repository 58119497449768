import { render, staticRenderFns } from "./teamCreate.vue?vue&type=template&id=b3c97864&scoped=true&"
import script from "./teamCreate.vue?vue&type=script&lang=js&"
export * from "./teamCreate.vue?vue&type=script&lang=js&"
import style0 from "./teamCreate.vue?vue&type=style&index=0&id=b3c97864&prod&lang=less&scoped=true&"
import style1 from "./teamCreate.vue?vue&type=style&index=1&id=b3c97864&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b3c97864",
  null
  
)

export default component.exports