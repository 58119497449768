<template>
  <div class="role-warp" style="width: 55vw;">
    <div class="h-full">
      <div style="height: calc(100% - 52px);overflow-y: auto;padding: 0 15px;">
        <div v-for="(item, index) in messageList" :key="index" class="massage-item">
          <div class="message-content flex items-center justify-between flex-wrap">
            <div style="width: 70%;">
              <div class="part1 flex items-center flex-wrap">
                <div class="message-title">通知</div>
                <div class="message-time">{{ item.createTime }}</div>
              </div>
              <div class="part2 flex items-center flex-wrap">
                <div class="m-right-bottom" style="width: 45%;">{{ item.handoverInfo }}</div>
                <div class="m-right-bottom" v-if="item.status == 0"><img class="align-middle" src="@/assets/img/new/message-none.svg" style="margin-right: 8px;" />未完成数据权限移交</div>
                <div class="m-right-bottom" style="" v-else><img class="align-middle" src="@/assets/img/new/message-success.svg" style="margin-right: 8px;" />完成数据权限移交</div>
                <div class="m-right-bottom" v-if="item.status == 1">移交对象：{{ item.handoverRealname }}</div>
              </div>
            </div>
            <div>
              <div class="message-btn btnme1 text-center cursor-pointer" v-if="item.status == 0" @click="handleOver(item)">去移交</div>
              <div class="message-btn btnme2 text-center" v-else>完成移交</div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <el-pagination
          layout="prev, pager, next"
          :total="total"
          :current-page="pageNumber"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="618px"
      append-to-body
      custom-class="black-theme"
      title="选择移交对象"
      :modal="true"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div>
        <div class="invitation-content">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="用户角色：" prop="role">
              <!-- <el-select
                v-model="ruleForm.role"
                placeholder="请选择用户角色"
                class="black-select"
              >
                <el-option v-for="item in tabList2" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select> -->
              <el-input v-model="ruleForm.role" placeholder="请选择用户角色" :disabled="true" class="black22-input22"></el-input>
            </el-form-item>
            <el-form-item label="用户名称：" prop="id">
              <el-select
                v-model="ruleForm.id"
                placeholder="请选择"
                class="black-select"
                @change="selectChange"
              >
                <el-option v-for="item in tabList3" :key="item.id" :value="item.id" :label="item.realname"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户账号：" prop="accountName">
              <!-- <el-select
                v-model="ruleForm.accountName"
                placeholder="请选择"
                class="black-select"
              >
                <el-option v-for="item in tabList2" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select> -->
              <el-input v-model="ruleForm.accountName" placeholder="请输入用户账号" :disabled="true" class="black22-input22"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="flex items-center justify-center">
          <div class="dialog-btn diabtn1 text-center cursor-pointer" @click="reset">取消</div>
          <div class="dialog-btn diabtn2 text-center cursor-pointer" @click="exitTo">移交</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { get, post } from "@/utils/http.js";
export default {
  components: {  },
  data() {
    return {
      messageList: [],
      currentPage4: 4,
      dialogVisible: false,
      ruleForm: {
        role: '',
        id: undefined,
        realName: '',
        accountName: ''
      },
      rules: {
        id: [
          { required: true, message: "请选择用户名称", trigger: "blur", type: "number" },
        ]
      },
      tabList3: [
        {
          id: 1,
          name: 'aaa'
        },
        {
          id: 2,
          name: 'nnn'
        }
      ],
      total: 10,
      pageNumber: 1,
      pageSize: 10,
      exitCompanyInfo: {},
      exitCompanyRoleName: ''
    };
  },
  props: {
    // messageList: {
    //   type: Array,
    //   default: () => []
    // }
  },
  computed: {
    
  },
  mounted() {
    
  },
  created() {
    this.init()
  },
  methods: {
    init () {
      let that = this
      let query = {}
      query.companyId = sessionStorage.getItem("companyId")
      query.pageNumber = that.pageNumber
      query.pageSize = that.pageSize
      post("/loginInfo/devolvedInfoList", query).then((res) => {
        console.log("resBBBBBBBBBCCCCCCCCCCC=====>", res)
        if (res.code == 200) {
          that.messageList = res.data.dataList
          that.pageNumber = res.data.pageNumber
          that.pageSize = res.data.pageSize
          that.total = res.data.total
        } else {
          that.$notify.error({
            title: '查询失败',
            message: res.msg,
            duration: 2000
          })
        }
      })
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pageNumber = val
      this.init()
    },
    handleOver (item) {
      const that = this
      that.dialogVisible = true
      var tabList3 = []
      const query = {}
      query.roleId = item.devolvedUserRoleId
      query.userId = item.devolvedUserid
      get("/loginInfo/getDevolvedUsers", query).then((res) => {
        console.log('=========================>',res)
        that.ruleForm.role = res.data.roleInfo.name
        that.exitCompanyInfo = item
        that.exitCompanyRoleName = res.data.roleInfo.name
        let users = res.data.users
        for (var i = 0; i < users.length; i++) {
          tabList3[i] = {}
          tabList3[i].id = users[i].id
          tabList3[i].realname = users[i].realname
          tabList3[i].username = users[i].username
        }
        that.tabList3 = tabList3
        that.dialogVisible =- true
        console.log('that.tabList3======>', that.tabList3)
      })
    },
    selectChange (e) {
      console.log(e)
      const idx = this.tabList3.findIndex(function (item) {
        return item.id == e
      })
      this.ruleForm.accountName = this.tabList3[idx].username
      this.ruleForm.realName = this.tabList3[idx].realname
    },
    reset () {
      this.pageNumber = 1
      this.ruleForm = {
        role: '',
        id: undefined,
        realName: '',
        accountName: ''
      },
      this.dialogVisible = false
    },
    exitTo () {
      const that = this
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const query = {}
          query.companyId = sessionStorage.getItem('companyId')
          query.id = that.exitCompanyInfo.id
          query.devolvedRealname = that.exitCompanyInfo.devolvedRealname
          query.devolvedUserRoleId = that.exitCompanyInfo.devolvedUserRoleId
          query.devolvedUserid = that.exitCompanyInfo.devolvedUserid
          query.devolvedUsername = that.exitCompanyInfo.devolvedUsername
          
          query.handoverInfo = "【" + that.exitCompanyRoleName + "】" + that.exitCompanyInfo.devolvedRealname + "，退出企业团队"
          query.handoverRealname = that.ruleForm.realName
          query.handoverUserid = that.ruleForm.id
          query.handoverUsername = that.ruleForm.accountName
          post("/loginInfo/saveDevolvedAndQuit", query).then((res) => {
            console.log(res)
            if (res.code == 200) {
              that.$message.success('移交成功!!')
              that.reset()
              that.pageNumber = 1
              that.init()
            } else {
              that.$notify.error({
                title: '移交失败',
                message: res.msg,
                duration: 2000
              })
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      })
    },
  },
};
</script>
<style lang="less" scoped>
.role-warp {
  width: 50vw;
  height: 75vh;
  color: #fff;
  position: absolute;

}
.massage-item {
  margin-bottom: 24px;
  .message-content {
    padding: 16px 24px 12px;
    background: linear-gradient(180deg, rgba(24, 144, 255, 0.1) 0%, rgba(24, 144, 255, 0.1) 100%);
    border-radius: 8px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
  }
  .message-title {
    font-size: 16px;
  }
  .message-time {
    font-size: 12px;
    color: #989898;
    margin-left: 24px;
  }
  .part1 {
    margin-bottom: 24px;
  }
  .part2 {
    // min-width: 25vw;
    .m-right-bottom {
      margin-right: 2.5vw;
      margin-bottom: 12px;
    }
  }
  .message-btn {
    width: 120px;
    padding: 6px 0 10px;
  }
  .btnme1 {
    background: #0084FF;
  }
  .btnme2 {
    background: #0A3764;
  }
}
.dialog-btn {
  width: 114px;
  height: 32px;
  line-height: 32px; 
}
.diabtn1 {
  border: 1px solid #1890FF;
  margin-right: 40px;
}
.diabtn2 {
  background: #0084FF;
}
</style>
<style lang="less">
.el-pagination {
  text-align: right;
  margin-top: 20px;
  .btn-prev,
  .el-pager,
  .btn-quicknext,
  .btn-quickprev,
  .btn-next {
    color: #fff;
    background: rgba(0, 0, 0, 0) !important;
    li {
      background: rgba(0, 0, 0, 0) !important;
    }
  }
  .el-pagination__total {
    color: #fff;
  }
}
.black22-input22 .el-input__inner {
  color: #333333 !important;
}
</style>