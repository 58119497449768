// 引入同级文件的service（axios实例）
import {services,service} from './services'
import {newPayservices} from './services'

// 封装正常gets请求
export function gets(url, params,responseType) {
    const config = {
        method: "get",
        url,
        responseType,
    }
    if (params) { config.params = params }
    return services(config)
}

// 封装正常post请求
export function posts(url, params,responseType,timeout,cancelToken) {
    const config = {
        method: "post",
        url,
        responseType,
        timeout,
        cancelToken
    }
    if (params) { config.data = params }
    return services(config)
}
export function deletes(url, params) {
    const config = {
        method: "delete",
        url,
    }
    if (params) { config.params = params }
    return services(config)
}


// 封装正常post请求
export function post(url, params) {
    const config = {
        method: "post",
        url,
    }
    if (params) { config.data = params }
    return service(config)
}
export function del(url, params) {
    const config = {
        method: "delete",
        url,
    }
    if (params) { config.params = params }
    return service(config)
}
// 封装正常post请求
export function get(url, params) {
    const config = {
        method: "get",
        url,
    }
    if (params) { config.params = params }
    return service(config)
}
export function newPaygets(url, params) {
    const config = {
        method: "get",
        url,
    }
    if (params) { config.params = params }
    return newPayservices(config)
}

export const publicApi = {
    get(url, params, option = {}) {
        return service.get(url, {
            params,
            ...option
        })
    },
    post(url, data, responseType, option = {}) {
        return service({
            method: 'POST',
            url,
            data,
            responseType: responseType,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            ...option
        })
    },
    put(url, data, option = {}) {
        return service({
            method: 'PUT',
            url,
            data,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            ...option
        })
    },
    /**
     * 删除
     * @param url
     * @param params
     * @returns {AxiosPromise}
     */
    delete(url, params, option = {}) {
        return service({
            url,
            method: 'DELETE',
            params,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            withCredentials: true,
            ...option
        })
    },
    /***
     * 上传文件接口
     * @param url
     * @param formData
     * @returns {AxiosPromise}
     */
    upload(url, formData, responseType, option = {}) {
        return service({
            url,
            method: 'POST',
            data: formData,
            responseType: responseType,
            ...option
        })
    },
    /**
     * GET下载文件
     * @param url
     * @param data
     * @returns {AxiosPromise}
     */
    download(url, params, option = {}) {
        return service({
            url,
            method: 'GET',
            responseType: 'arraybuffer',
            observe: 'response',
            params,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            withCredentials: true,
            ...option
        })
    },
    /**
     * POST下载文件（常用于提交json数据下载文件）
     * @param url
     * @param data
     * @returns {AxiosPromise}
     */
    postDownload(url, data, option = {}) {
        return service({
            url,
            method: 'POST',
            responseType: 'arraybuffer',
            observe: 'response',
            data,
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            withCredentials: true,
            ...option
        })
    },
    getfils(url, params, responseType, option = {}) {
        return service({
            method: 'GET',
            url,
            params,
            responseType: responseType,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            ...option
        })
    },
    postfils(url, params, responseType, option = {}) {
        return service({
            method: 'post',
            url,
            data: params,
            responseType: responseType,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            },
            ...option
        })
    }
}