import router from "./src/router";
// 做一个全局守卫 防止屌丝登录页和首页通过路径来回切换 因为是全局的所以文件放在全局中
router.beforeEach((to, from, next) => {
    const hasToken = sessionStorage.getItem('authorization')
    setTimeout(() => {
        if (to.meta.DontIntercept) {
            next()
        } else {
            // 有token  去登录页，不让去。去的不是登录页，放行
            if (hasToken) {
                // 有权限  那些路由都能去，没权限重定向到首页
                if (to.path === "/") {
                    next("/ThreeTerminal")
                } else {
                    next()
                }
            } else {
                // 没有token  去登录页，放行。去的不是登录页，让你去登录页
                if (to.path === "/" || to.path === "/redirectView") {
                    next()
                } else {
                    next({
                        path: "/"
                    })
                }
            }
        }
    }, 50);
})

// 记得将这个文件引入man。js中