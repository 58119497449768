<template>
  <div class="user-warp">
    <div class="header">
      <div class="search">
        <div class="item">
          <span class="form-label">角色名称：</span>
          <el-input
            placeholder="请输入角色名称"
            size="small"
            v-model="name"
          ></el-input>
          <span class="form-label">所属公司：</span>
          <el-cascader
            size="small"
            v-model="cascaderValue"
            :options="cascaderOptions"
            :props="{ expandTrigger: 'click' }"
            @change="queryTable(1)"
          ></el-cascader>
        </div>
        
        <div class="btn-warp">
          <el-button size="mini" type="primary" @click="queryTable(1)"
            >查 询</el-button
          >
          <el-button size="mini" plain class="black-btn" @click="name = ''"
            >重 置</el-button
          >
        </div>
      </div>
      <el-button
        v-if="adminStatus != 0"
        size="small"
        type="primary"
        @click="
          drawer = true;
          judge = true;
        "
        >添加角色&nbsp;<i class="el-icon-circle-plus-outline"></i
      ></el-button>
    </div>
    <div class="content">
      <!-- <el-row v-if="adminStatus==1" class="w-full tabs">
        <el-col
          :span="4"
          class="tab-pane"
          v-for="(item, index) in tabList"
          :key="index"
          :class="num == index ? 'tab-pane-active' : ''"
          @click.native="viewChange(index, item.id)"
          >{{ item.name }}</el-col
        >
      </el-row> -->
      <el-table
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column prop="name" label="角色名称" width="200">
        </el-table-column>
        <el-table-column align="left" prop="menuName" label="功能权限">
          <template slot-scope="scope">
            <span
              v-for="(item, index) in scope.row.menuName.split(',')"
              :key="index"
            >
              <el-tag v-if="item" size="small">{{ item }}</el-tag>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="remark"
          label="备注"
          width="300"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column v-if="adminStatus == 1" label="操作" width="200">
          <template slot-scope="scope">
            <el-button type="text" @click="editRole(scope.row)">编辑</el-button>
            <el-button
              type="text"
              @click.native="deleteRole(scope.row)"
              style="color: rgba(255, 75, 75, 1)"
              >删除</el-button
            >
          </template>
          <!-- <el-dialog
            :visible.sync="dialogVisible"
            width="30%"
            append-to-body
            custom-class="black-theme"
            title="删除角色"
            modal
          >
            <span><i class="el-icon-question"></i>确定删除xxx这个角色吗？</span>
            <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="dialogVisible = false"
                >确 定</el-button
              >
            </span>
          </el-dialog> -->
        </el-table-column>
      </el-table>
      <el-drawer
        ref="drawer"
        :visible.sync="drawer"
        title="添加角色"
        append-to-body
        :modal="false"
        size="560px"
        :before-close="closeDrawer"
      >
        <el-form label-width="140px" :model="form" ref="form" :rules="rules">
          <el-form-item label="角色名称：" prop="username">
            <el-input
              size="small"
              v-model="form.username"
              placeholder="请输入角色名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="功能权限：">
            <el-tree
              ref="tree"
              :data="treeData"
              show-checkbox
              node-key="id"
              default-expand-all
              :props="defaultProps"
            >
            </el-tree>
          </el-form-item>
          <el-form-item label="备注：">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入备注信息"
              v-model="form.remark"
            >
            </el-input>
          </el-form-item>
        </el-form>
        <div class="foot-btn">
          <el-button
            size="small"
            plain
            class="black-btn"
            @click="drawer = false"
            >取消</el-button
          >
          <el-button size="small" type="primary" @click="save">{{
            judge ? "保存" : "修改"
          }}</el-button>
        </div>
      </el-drawer>
    </div>
    <el-pagination
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        :current-page="pageNumber"
        @current-change="currentChange"
      >
      </el-pagination>
  </div>
</template>

<script>
import { post, get } from "@/utils/http.js";
export default {
  props: ["tabList", "treeData"],
  data() {
    return {
      num: 0,
      total: 10,
      pageNumber: 1,
      pageSize: 10,
      name: "",
      drawer: false,
      judge: false,
      roleId: "",
      // tabList: [],
      // dialogVisible: false,
      tableData: [],
      cascaderValue: [],
      cascaderOptions: [],
      form: {
        username: "",
        remark: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入角色名称", trigger: "blur" },
        ],
      },
      defaultProps: {
        children: "children",
        label: "name",
      },
    };
  },
  computed: {
    adminStatus() {
      return sessionStorage.getItem("admin");
      // return 2
    },
  },
  created() {
    this.selectCompanyOfFive()
  },
  methods: {
    selectCompanyOfFive() {
      const admin = this.adminStatus === '1' ? true : false
      const companyId = admin ? '0' : sessionStorage.getItem('companyId')
      get('/loginInfo/selectCompanyOfFive', {companyId}).then(res => {
        this.cascaderOptions = res.data
        this.cascaderValue = [res.data[0].value, res.data[0].children[0].value]
        this.queryTable(1)
      })
    },
    closeDrawer(done) {
      console.log("*********");
      this.$refs.tree.setCheckedKeys([]);
      this.$refs.form.resetFields();
      this.form.remark = "";
      done();
    },
    deleteRole(row) {
      const title = "系统提示";
      const content = `是否删除『 ${row.name} 』角色？`;
      this.$alert(content, title, {
        customClass: "customClass",
        showCancelButton: true,
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            post("/loginInfo/deleteRole", { id: row.id }).then((res) => {
              // if(res.code == 200){
              // }else{
              //   this.$message.error(res.msg)
              // }
              if (res.code == 200) {
                this.$message.success("删除成功");
                this.queryTable(this.pageNumber);
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.$message("已取消");
          }
        },
      });
    },
    editRole(row) {
      this.drawer = true;
      this.judge = false;
      this.roleId = row.id;
      this.form.remark = row.remark
      this.form.username = row.name
      this.$nextTick(() => {
        this.$refs.tree.setCheckedKeys(row.menusIds)
      })
      // get("loginInfo/menus", { id: row.id }).then((res) => {
      //   this.form.remark = row.remark;
      //   this.form.username = row.name;
      //   this.$refs.tree.setCheckedKeys(res.menuIdList);
      // });
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            id: this.judge ? null : this.roleId,
            unitId: this.adminStatus == 2 ? null : this.tabList[this.num].id,
            name: this.form.username,
            remark: this.form.remark,
            menusIds: this.$refs.tree.getCheckedKeys(),
            companyId: this.cascaderValue[1],
          };
          post("loginInfo/saverole", params).then((res) => {
            if (res.code == 200) {
              this.$refs.form.resetFields()
              this.drawer = false;
              this.queryTable(this.pageNumber);
              this.$message.success(`角色${this.judge ? "创建" : "修改"}成功`);
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
      console.log(this.currentNodeKey, this.$refs.tree.getCheckedKeys());
    },
    currentChange(pageNumber) {
      this.queryTable(pageNumber);
      this.pageNumber = pageNumber;
    },
    queryTable(pageNumber, unitId) {
      console.log(this.cascaderValue)
      const params = {
        unitId: unitId || this.tabList[this.num].id, // 五方的id
        pageNumber, //  当前页面
        pageSize: this.pageSize, // 页面大小
        companyId: this.cascaderValue[1], // 公司id,超级管理员为0
        name: this.name, // 对应的查询字段
        
      };
      post("loginInfo/queryRole", params).then((res) => {
        if (res.code == 200) {
          this.total = res.data.total;
          this.tableData = res.data.arrayList;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    viewChange(index, id) {
      (this.name = ""), (this.num = index);
      this.queryTable(1, id);
    },
    tableRowClassName({ rowIndex }) {
      // console.log(row);
      if (rowIndex % 2 == 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="less" scope>
.el-tag {
  margin: 4px;
  border: 1px solid rgba(24, 144, 255, 0.8) !important;
  color: #0080ff !important;
  background: rgba(0, 0, 0, 0) !important;
}
.foot-btn {
  width: 100%;
  padding: 1rem;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  text-align: right;
}
.el-textarea {
  // width: calc(100% - 1rem);
}
.el-textarea__inner {
  color: #fff !important;
  background: rgba(0, 0, 0, 0.9) !important;
}
.el-tree {
  max-height: 500px;
  overflow: auto;
  color: #fff !important;
  padding: 1rem;
  // margin-right: 1rem;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.9) !important;
  .el-tree-node:focus > .el-tree-node__content {
    background: rgba(24, 144, 255, 0.2) !important;
  }
  .el-tree-node__content:hover {
    background: rgba(24, 144, 255, 0.2) !important;
  }
}
.el-input__inner {
  color: #fff !important;
  background: rgba(0, 0, 0, 0.9);
}
.el-drawer {
  background: rgba(0, 0, 0, 0.9) !important;
  .el-drawer__header {
    color: #fff !important;
    font-size: 22px;
    font-weight: 500;
  }
  .el-drawer__body {
    padding-right: 1rem;
  }
  .el-input__inner {
  color: #fff !important;
  background: rgba(0, 0, 0, 0.9) !important;
}
}
.user-warp {
  width: 75vw;
  height: 75vh;
  color: #fff;
  position: absolute;
  .header {
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    padding: 24px 32px;
    box-sizing: border-box;
    border-radius: 8px;
    background: rgba(24, 144, 255, 0.2);
    .search {
      width: calc(100% - 150px);
      display: flex;
      .item {
        // width: calc(100% - 200px);
        width: 60%;
        display: flex;
        align-items: center;
        .form-label {
          margin-left: 14px;
          display: inline-block;
          width: 180px;
        }
        .el-input{
          // width: 350px;
        }
        .el-input__inner {
          color: #fff;
          background: rgba(0, 0, 0, 0);
          .el-range-separator {
            color: #fff;
          }
          .el-range-input {
            background: rgba(0, 0, 0, 0);
          }
        }
      }
      .btn-warp {
        margin-left: 20px;
      }
    }
  }
  .content {
    width: 100%;
    overflow: auto;
    height: calc(100% - 80px);
    border-radius: 8px 8px 0 0;
    background: linear-gradient(
      to bottom,
      rgba(24, 144, 255, 0.1),
      rgba(0, 0, 0, 0)
    );
    .tabs {
      .tab-pane {
        cursor: pointer;
        text-align: center;
        line-height: 80px;
        height: 80px;
        background: rgba(24, 144, 255, 0.05);
        border-radius: 8px 8px 0px 0px;
        transition: all 0.3s;
      }
      .tab-pane:hover {
        background: rgba(24, 144, 255, 0.2);
      }
      .tab-pane-active {
        background: rgba(24, 144, 255, 0.2);
      }
    }
    .btn-warp {
      padding: 12px 32px;
    }
    .el-table {
      padding: 0 1rem;
      background-color: transparent; // 背景透明
      th,
      tr,
      td {
        text-align: left;
        background-color: transparent; // 背景透明
        border: none;
        color: #fff; // 修改字体颜色
        font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
      }
      .warning-row {
        background: rgba(0, 0, 0, 0.1) !important;
      }
      tr:hover > td {
        background: rgba(24, 144, 255, 0.2) !important;
      }
    }

    // 去掉最下面的那一条线
    .el-table::before {
      height: 0px;
    }
    .el-pagination {
      text-align: right;
      margin: 20px 20px 0 0;
      .btn-prev,
      .el-pager,
      .btn-next {
        color: #fff;
        background: rgba(0, 0, 0, 0) !important;
        li {
          background: rgba(0, 0, 0, 0) !important;
        }
      }
    }
  }
}
</style>