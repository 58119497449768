<template>
  <div class="role-warp">
    <div class="header">
      <div class="item">
        <span>用户账号：</span>
        <el-input
          placeholder="请输入用户 ID/账号"
          size="small"
          v-model="phone"
        ></el-input>
      </div>
      <div class="item">
        <span>用户名：</span>
        <el-input
          placeholder="请输入用户名"
          size="small"
          v-model="username"
        ></el-input>
      </div>
      <div class="item">
        <span>注册时间：</span>
        <el-date-picker
          size="small"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          v-model="Time"
          @change="timeChange"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="btn-warp">
        <el-button size="small" type="primary" @click="query">查 询</el-button>
        <el-button size="small" plain class="reset" @click="reset"
          >重 置</el-button
        >
      </div>
      <!-- <el-button size="small" type="primary" @click.native="addUser()"
        >创建用户<i class="el-icon-circle-plus-outline"></i
      ></el-button> -->
    </div>
    <div class="content">
      <!-- <div class="btn-warp">
        <el-button
          size="small"
          type="primary"
          :id="outTableData.id"
          :name="outTableData.name"
          >导出数据</el-button
        >
      </div> -->
      <el-table
        id="outTable"
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        class="wqewqds"
      >
        <el-table-column type="selection" width="80"> </el-table-column>
        <el-table-column prop="id" label="用户ID" width="80">
        </el-table-column>
        <el-table-column prop="phone" label="用户账号" width="120">
        </el-table-column>
        <el-table-column prop="realname" label="用户名" width="120"> </el-table-column>
        <!-- <el-table-column prop="institution" label="所属机构" show-overflow-tooltip width="250">
          <template slot-scope="scope">
            <p>{{ scope.row.institution?scope.row.institution.split(':')[0]:'' }}</p>
            <p>{{ scope.row.institution?scope.row.institution.split(':')[1]:'' }}</p>
          </template>
        </el-table-column> -->
        <el-table-column prop="roles" label="所属角色"></el-table-column>
        <!-- <el-table-column prop="sysRoleList" label="所属角色">
          <template slot-scope="scope">
            <span v-for="item in scope.row.sysRoleList" :key="item.id"
              >{{ item.name }},</span
            >
          </template>
        </el-table-column> -->
        <el-table-column prop="createTime" label="注册时间"> </el-table-column>
        <el-table-column prop="inviterName" label="邀请人"> </el-table-column>
        <el-table-column prop="statusName" label="账户启用状态">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.statusName"
              inactive-color="rgba(8, 16, 28, 0.50)"
              active-text="启用"
              inactive-text="禁用"
              @change="switchChange($event, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="editUser(scope.row)">编辑</el-button>
            <el-button
              type="text"
              style="color: rgba(255, 75, 75, 1)"
              @click="deleteUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :current-page="pageNumber"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="618px"
      append-to-body
      custom-class="black-theme"
      title="编辑用户"
      :modal="false"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div>
        <div class="invitation-content">
          <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
            <el-form-item label="用户名：" prop="realname">
              <el-input
                v-model="form.realname"
                placeholder="请输入用户名"
                class="black-input"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="用户账号：" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号"
                class="black-input"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="用户角色：" prop="role">
              <el-select
                v-model="form.role"
                placeholder="请选择用户角色"
                class="black-select"
              >
                <el-option v-for="item in tabList2" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="用户角色：" prop="role">
              <el-select
                v-model="form.role"
                placeholder="请选择用户角色"
                class="black-select"
              >
                <el-option v-for="item in tabList2" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户名称：" prop="name">
              <el-select
                v-model="form.name"
                placeholder="请选择"
                class="black-select"
              >
                <el-option v-for="item in tabList2" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户账号：" prop="accountName">
              <el-select
                v-model="form.accountName"
                placeholder="请选择"
                class="black-select"
              >
                <el-option v-for="item in tabList2" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </el-form-item> -->
          </el-form>
        </div>
        <div class="flex items-center justify-center">
          <div class="dialog-btn btn1 text-center cursor-pointer" @click="cancelRenow">取消</div>
          <div class="dialog-btn btn2 text-center cursor-pointer" @click="exitTo">确认修改</div>
        </div>
      </div>
    </el-dialog>
    <!-- <el-dialog
      :visible.sync="dialogVisible"
      width="618px"
      append-to-body
      custom-class="black-theme"
      :title="selects"
      :modal="false"
      :close-on-click-modal="false"
      destroy-on-close
      @closed="resetForm"
    >
      <el-form
        v-if="userCreate"
        label-width="100px"
        :model="form"
        :rules="rules"
        ref="form"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户名：" prop="realname">
              <el-input
                v-model="form.realname"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户账号：" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属角色：" prop="role">
              <el-select
                v-model="form.role"
                placeholder="请选择用户角色"
                class="black-select"
              >
                <el-option v-for="item in tabList2" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
              <el-cascader
                v-model="form.role"
                :options="companyAndRole"
                @change="handleChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                placeholder="请输入新密码"
                type="password"
                v-model="form.password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="checkPass">
              <el-input
                placeholder="请确认密码"
                type="password"
                v-model="form.checkPass"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form
        v-else
        label-width="100px"
        :model="form"
        :rules="newRules"
        ref="form"
        class="demo-ruleForm"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户名：" prop="realname">
              <el-input
                v-model="form.realname"
                placeholder="请输入用户名"
                class="black-input"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户账号：" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号"
                class="black-input"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属角色：" prop="role">
              <el-select
                v-model="form.role"
                placeholder="请选择用户角色"
                class="black-select"
              >
                <el-option v-for="item in tabList2" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select>
              <el-cascader
                v-model="form.role"
                :options="companyAndRole"
                @change="handleChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item label="修改密码：" prop="newPassword">
              <el-input
                placeholder="如需修改请输入新密码"
                type="password"
                v-model="form.newPassword"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="确认密码：" prop="newCheckPass">
              <el-input
                placeholder="请确认修改密码"
                type="password"
                v-model="form.newCheckPass"
                autocomplete="off"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button size="small" type="primary" @click="createUser"
          >确 定</el-button
        >
      </span>
    </el-dialog> -->
    <el-dialog
      :visible.sync="dialogYijiaoVisible"
      width="618px"
      append-to-body
      custom-class="black-theme"
      title="选择移交对象"
      :modal="false"
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div>
        <div class="invitation-content">
          <el-form :model="ruleForm" :rules="rules2" ref="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="用户角色：" prop="role">
              <!-- <el-select
                v-model="ruleForm.role"
                placeholder="请选择用户角色"
                class="black-select"
              >
                <el-option v-for="item in tabList3" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select> -->
              <el-input v-model="ruleForm.role" placeholder="请选择用户角色" :disabled="true" class="black22-input22"></el-input>
            </el-form-item>
            <el-form-item label="用户名称：" prop="id">
              <el-select
                v-model="ruleForm.id"
                placeholder="请选择"
                class="black-select"
                @change="selectChange"
              >
                <el-option v-for="item in tabList3" :key="item.id" :value="item.id" :label="item.realname"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="用户账号：" prop="accountName">
              <!-- <el-select
                v-model="ruleForm.accountName"
                placeholder="请选择"
                class="black-select"
              >
                <el-option v-for="item in tabList5" :key="item.id" :value="item.id" :label="item.name"></el-option>
              </el-select> -->
              <el-input v-model="ruleForm.accountName" placeholder="请输入用户账号" :disabled="true" class="black22-input22"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <div class="flex items-center justify-center">
          <div class="dialog-btn btn1 text-center cursor-pointer" @click="cancelRenow">取消</div>
          <div class="dialog-btn btn2 text-center cursor-pointer" @click="exitDelete">移交并删除</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { post, posts, get } from "@/utils/http.js";
export default {
  props: ["companyAndRole"],
  data() {
    const regTel =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    const handlePhoneChange = (rule, value, callback) => {
      if (!regTel.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        this.handlePhoneCheck(rule, value, callback);
      }
    };
    // var validatePass = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请输入密码"));
    //   } else {
    //     if (this.form.checkPass !== "") {
    //       this.$refs.form.validateField("checkPass");
    //     }
    //     callback();
    //   }
    // };
    // var validatePass2 = (rule, value, callback) => {
    //   if (value === "") {
    //     callback(new Error("请再次输入密码"));
    //   } else if (value !== this.form.password) {
    //     callback(new Error("两次输入密码不一致!"));
    //   } else {
    //     callback();
    //   }
    // };
    // var newValidatePass = (rule, value, callback) => {
    //   if (this.form.newCheckPass !== "") {
    //     this.$refs.form.validateField("newCheckPass");
    //   }
    //   return callback();
    // };
    var newValidatePass2 = (rule, value, callback) => {
      if (value !== this.form.newPassword) {
        return callback(new Error("两次输入密码不一致!"));
      } else {
        return callback();
      }
    };
    return {
      username: "",
      phone: "",
      endTime: "",
      startTime: "",
      Time: "",
      total: 10,
      pageNumber: 1,
      pageSize: 10,
      dialogVisible: false,
      dialogYijiaoVisible: false,
      tableData: [],
      outTableData: {
        id: "outTable",
        name: "交易数据",
      },
      userCreate: true,
      form: {
        userid: null,
        phone: "",
        realname: "",
        role: undefined,
      },
      changeValue: "",
      selects: "",
      selectId: "",
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" }
        ],
        realname: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        role: [
          { required: true, message: "请选择用户角色", trigger: "blur", type: "number" },
        ],
      },
      newRules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: handlePhoneChange, trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        role: [{ required: true, message: "请选择用户角色", trigger: "blur" }],
        // newPassword: [{ validator: newValidatePass, trigger: "blur" }],
        newCheckPass: [{ validator: newValidatePass2, trigger: "blur" }],
      },
      roleList: [
        {
          value: "aaa",
          label: "宝业集团",
          children: [
            {
              value: "basic",
              label: "管理员",
            },
            {
              value: "form",
              label: "Form",
            },
            {
              value: "data",
              label: "Data",
            },
            {
              value: "notice",
              label: "Notice",
            },
          ],
        },
        {
          value: "zujian",
          label: "中天建设",
          children: [
            {
              value: "basic",
              label: "Basic",
            },
            {
              value: "form",
              label: "Form",
            },
            {
              value: "data",
              label: "Data",
            },
            {
              value: "notice",
              label: "Notice",
            },
          ],
        },
      ],
      tabList2: [],
      tabList3: [],
      tabList4: [],
      tabList5: [],
      ruleForm: {
        role: '',
        id: undefined,
        realName: '',
        accountName: ''
      },
      rules2: {
        id: [
          { required: true, message: "请选择用户名称", trigger: "blur", type: "number" },
        ]
      },
      exitCompanyInfo: {},
      exitCompanyRoleName: '',
    };
  },
  created() {
    this.init(1);
  },
  methods: {
    handleChange() {
      console.log("this.form.role", this.form.role);
    },
    deleteUser(user) {
      const that = this
      const title = "删除用户";
      const content = "确定删除【" + user.realname + "】这个用户吗？删除用户前请先移交该用户的数据权限！";
      that.$alert(content, title, {
        customClass: "customClass",
        showCancelButton: true,
        confirmButtonText: "立即移交",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            var tabList3 = []
            const query = {}
            query.roleId = user.roleId
            query.userId = user.id
            get("/loginInfo/getDevolvedUsers", query).then((res) => {
              if (res.code == 200) {
                that.ruleForm.role = res.data.roleInfo.name
                that.exitCompanyInfo = user
                that.exitCompanyRoleName = res.data.roleInfo.name
                let users = res.data.users
                for (var i = 0; i < users.length; i++) {
                  tabList3[i] = {}
                  tabList3[i].id = users[i].id
                  tabList3[i].realname = users[i].realname
                  tabList3[i].username = users[i].username
                }
                that.tabList3 = tabList3
                that.dialogYijiaoVisible = true
              } else {
                that.$notify.error({
                  title: '查询失败',
                  message: res.msg,
                  duration: 2000
                })
              }
            });
          } else {
            return false;
          }
        },
      });
    },
    editUser(user) {
      console.log(user);
      this.form.phone = user.phone;
      this.form.realname = user.realname;
      get("/loginInfo/getCompanyRoles", { companyId: sessionStorage.getItem("companyId") })
        .then((res) => {
          if (res.code == 200) {
            this.tabList2 = res.data
            // this.form.role = user.sysRoleList[0].id;
            this.form.role = user.roleId
            this.form.userid = user.id
            // console.log(this.form.role, res.data.role);
            this.dialogVisible = true;
          } else {
            this.$notify.error({
              title: '查询失败',
              message: res.msg,
              duration: 2000
            })
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    resetForm() {
      
      this.form = {
        phone: "",
        username: "",
        role: "",
        password: "",
        checkPass: "",
        newPassword: "",
        newCheckPass: "",
      };
      console.log(this.form);
        this.$refs.form.resetFields();
    },
    query() {
      this.init(1);
    },
    createUser() {
      // const params = {
      //   unitId:this.tabList[this.num].id,
      //   name: this.form.username,
      //   remark: this.form.remark,
      //   menusIds: this.$refs.tree.getCheckedKeys(),
      //   companyId: sessionStorage.getItem("companyId"),
      // };
      if (this.userCreate) {
        this.$refs["form"].validate((valid) => {
          if (valid) {
            post("/loginInfo/saveUser", this.form).then((res) => {
              if (res.code == 200) {
                this.$refs.form.resetFields();
                this.dialogVisible = false;
                this.init(this.pageNumber);
                this.$message.success("用户创建成功");
              } else {
                this.$notify.error({
                  title: '创建失败',
                  message: res.msg,
                  duration: 2000
                })
              }
            });
          }
        });
      } else {
        const { phone, username, role, newCheckPass } = this.form;
        const params = {
          phone,
          username,
          role,
          id: this.selectId,
          password: newCheckPass,
        };
        // params.password = this.newCheckPass;
        console.log(this.form);
        if (this.form.newPassword == this.form.newCheckPass) {
          post("/loginInfo/saveUser", params).then((res) => {
            if (res.code == 200) {
              this.$refs.form.resetFields();
              this.dialogVisible = false;
              this.init(this.pageNumber);
              this.$message.success("用户修改成功");
            } else {
              this.$notify.error({
                title: '修改失败',
                message: res.msg,
                duration: 2000
              })
            }
          });
        } else {
          // this.$message.error('两次密码不一致')
          this.$refs["form"].validateField("newCheckPass");
        }
      }

      // this.$refs.form.resetFieldss();
    },

    reset() {
      (this.username = ""),
        (this.phone = ""),
        (this.endTime = ""),
        (this.startTime = ""),
        (this.Time = "");
      console.log(2);
    },
    timeChange(value) {
      (this.startTime = value[0]),
        (this.endTime = value[1]),
        console.log(value);
    },
    addUser() {
      this.selects = "创建用户";
      (this.userCreate = true), (this.changeValue = "密码：");
      this.form = {
        phone: "",
        username: "",
        role: "",
        password: "",
        checkPass: "",
        newPassword: "",
        newCheckPass: "",
      };
      this.dialogVisible = true;
    },
    switchChange(status, data) {
      post("/loginInfo/updateUserStatus", {
        id: data.id,
        statusName: status,
      }).then((res) => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        }
      });
    },
    // 正则检验过后检验该手机号是否注册   未注册的通过
    handlePhoneCheck(rule, value, callback) {
      if (this.selects == "创建用户") {
        posts("/User/checkPhone", { phone: value }).then((res) => {
          if (res.code == 0) {
            callback();
          } else {
            callback(new Error(res.msg));
          }
        });
      }
    },
    currentChange(pageNumber) {
      this.init(pageNumber);
      this.pageNumber = pageNumber;
    },
    init(pageNumber) {
      const params = {
        pageNumber,
        companyId: sessionStorage.getItem("companyId"),
        username: this.username,
        phone: this.phone,
        endTime: this.endTime,
        startTime: this.startTime,
        pageSize: this.pageSize,
        id: sessionStorage.getItem("accountID"),
      };
      post("/loginInfo/queryUsers", params).then((res) => {
        this.total = res.total;
        this.tableData = res.arrayList;
      });
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 !== 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
    exitTo () {
      const that = this
      that.$refs.form.validate((valid) => {
        if (valid) {
          console.log(valid)
          const query = {}
          query.id = that.form.userid
          query.realname = that.form.realname
          query.username = that.form.phone
          query.roleId = that.form.role
          query.companyId = sessionStorage.getItem ("companyId")
          post("/loginInfo/updateUser", query).then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.$refs.form.resetFields();
              this.dialogVisible = false;
              this.init(this.pageNumber);
              this.$message.success("用户修改成功");
            } else {
              that.$notify.error({
                title: '修改失败',
                message: res.msg,
                duration: 2000
              })
            }
          });
        }
      })
    },
    selectChange (e) {
      console.log(e)
      const idx = this.tabList3.findIndex(function (item) {
        return item.id == e
      })
      this.ruleForm.accountName = this.tabList3[idx].username
      this.ruleForm.realName = this.tabList3[idx].realname
    },
    cancelRenow () {
      this.pageNumber = 1
      this.ruleForm = {
        role: '',
        id: undefined,
        realName: '',
        accountName: ''
      },
      this.dialogVisible = false
      this.dialogYijiaoVisible = false
    },
    exitDelete () {
      const that = this
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const query = {}
          query.companyId = sessionStorage.getItem('companyId')
          query.devolvedRealname = that.exitCompanyInfo.realname
          query.devolvedUserRoleId = that.exitCompanyInfo.roleId
          query.devolvedUserid = that.exitCompanyInfo.id
          query.devolvedUsername = that.exitCompanyInfo.username
          
          query.handoverInfo = "【" + that.exitCompanyRoleName + "】" + that.exitCompanyInfo.realname + "，退出企业团队"
          query.handoverRealname = that.ruleForm.realName
          query.handoverUserid = that.ruleForm.id
          query.handoverUsername = that.ruleForm.accountName
          post("/loginInfo/saveDevolvedAndQuit", query).then((res) => {
            console.log(res)
            if (res.code == 200) {
              that.$message.success('移交成功!!')
              that.cancelRenow()
              that.pageNumber = 1
              that.init(1)
            } else {
              that.$notify.error({
                title: '移交失败',
                message: res.msg,
                duration: 2000
              })
            }
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.el-cascader {
  width: 100%;
  .el-input__suffix {
    right: 20px;
  }
}
.customClass {
  padding: 0 0 20px;
  border: 1px solid #666666;
  background-color: rgba(0, 0, 0, 0.8) !important;
  .el-message-box__header {
    .el-message-box__title {
      color: #fff !important;
    }
    // background-color: #0080ff !important;
  }
  .el-message-box__content {
    padding: 20px;
    color: #dbdbdb !important;
  }
}
.role-warp {
  width: 75vw;
  height: 75vh;
  color: #fff;
  position: absolute;
  .header {
    height: 80px;
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    padding: 24px 32px;
    box-sizing: border-box;
    border-radius: 8px 8px 0 0;
    background: rgba(24, 144, 255, 0.2);
    .item {
      width: 25%;
      display: flex;
      align-items: center;
      span {
        width: 6rem;
      }
      .el-input__inner {
        color: #fff;
        background: rgba(0, 0, 0, 0);
        // width: calc(100% - 1rem);
        .el-range-separator {
          color: #fff;
        }
        .el-range-input {
          background: rgba(0, 0, 0, 0);
        }
      }
    }
    .btn-warp {
      margin-left: 20px;
      .reset {
        color: #fff;
        background: rgba(0, 0, 0, 0);
      }
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 80px);
    background: linear-gradient(
      to bottom,
      rgba(24, 144, 255, 0.1),
      rgba(0, 0, 0, 0)
    );
    .btn-warp {
      padding: 12px 32px;
    }
    .el-table {
      background-color: transparent; // 背景透明
      th,
      tr,
      td {
        text-align: center;
        background-color: transparent; // 背景透明
        border: none;
        color: #fff; // 修改字体颜色
        font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
      }
      .warning-row {
        background: rgba(0, 0, 0, 0.1) !important;
      }
      tr:hover > td {
        background: rgba(24, 144, 255, 0.2) !important;
      }
      .el-switch__label {
        color: #fff;
      }
      .is-active {
        color: #0080ff !important;
      }
    }

    // 去掉最下面的那一条线
    .el-table::before {
      height: 0px;
    }

    .el-pagination {
      text-align: right;
      margin: 20px 20px 0 0;
      .btn-prev,
      .el-pager,
      .btn-quicknext,
      .btn-quickprev,
      .btn-next {
        color: #fff;
        background: rgba(0, 0, 0, 0) !important;
        li {
          background: rgba(0, 0, 0, 0) !important;
        }
      }
    }
  }
}
.invitation-content {
  width: 80%;
  margin-left: 10%;
}
.dialog-btn {
  width: 114px;
  height: 32px;
  line-height: 32px; 
}
.btn1 {
  border: 1px solid #1890FF;
  margin-right: 40px;
}
.btn2 {
  background: #0084FF;
}
.black-input .el-input__inner {
  height: 40px !important;
  line-height: 40px !important;
}
.customClass {
  width: 520px !important;
}
.wqewqds {
  height: calc(100% - 80px);
  overflow-y: auto;
}
</style>
