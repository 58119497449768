<template>
  <div>
    <div class="adminFirst">
      <header>
        <h1>欢迎进入数智管理云平台</h1>
        <h2>请输入企业基本信息</h2>
      </header>
      <main>
        <div class="main-box">
          <el-form label-width="130px" :model="form" :rules="rules" ref="form">
            <el-row>
              <el-col :span="12" style="padding-right: 1rem">
                <el-form-item label="公司名称：" prop="companyName">
                  <el-input
                    v-model="form.companyName"
                    placeholder="请输入公司名称"
                  ></el-input>
                </el-form-item>
                <el-form-item label="公司法人：" prop="legalRepresentative">
                  <el-input
                    v-model="form.legalRepresentative"
                    placeholder="请输入法人姓名"
                  ></el-input>
                </el-form-item>
                <el-form-item label="证件号码：" prop="idNumber">
                  <el-input
                    v-model="form.idNumber"
                    placeholder="请输入法人证件号码"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系电话：" prop="phone">
                  <el-input
                    v-model="form.phone"
                    placeholder="请输入联系电话"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" style="padding-right: 1rem">
                <el-form-item label="主体类型：" prop="atype">
                  <el-select v-model="form.atype" placeholder="请选择">
                    <el-option
                      v-for="(item, index) in tabList2"
                      :key="index"
                      :label="item.name"
                      :value="item.id"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="统一信用代码：" prop="code">
                  <el-input v-model="form.code"></el-input>
                </el-form-item>

                <el-form-item label="添加印章：" prop="seal">
                  <div class="previewSeal">
                    <template v-if="fileLists.length">
                      <div
                        v-for="(item, index) in fileList"
                        :key="index"
                        class="sealImg"
                      >
                        <img
                          class="h-full"
                          style="width: 118px"
                          :src="item.url"
                          alt=""
                        />
                      </div>
                    </template>

                    <div class="plus" @click="uploadDialogVisible = true">
                      <i class="el-icon-plus" style="font-size: 36px"></i>
                    </div>
                  </div>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="dialogImageUrl" alt="" />
                  </el-dialog>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <div class="box-footer">
            <!-- <button class="back btn" @click="close">退出</button> -->
            <el-tooltip
              class="item btn"
              effect="dark"
              content="退出会返回登录页"
              placement="top"
            >
              <el-button @click="close" type="primary" size="mini" style="margin-right: 20px;">退 出</el-button>
            </el-tooltip>
            <el-button @click="saveCompany()" type="primary" size="small">保 存</el-button>
          </div>
        </div>
        <el-dialog
          :visible.sync="uploadDialogVisible"
          title="添加印章"
          width="530px"
          append-to-body
          custom-class="seal-dialog"
        >
          <el-upload
            action="#"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :on-change="onChange"
            :file-list="fileList"
            :http-request="httpRequest"
            :limit="1"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
          <p>图片格式：jpg,png</p>
          <el-form
            label-width="100px"
            :model="formSeal"
            :rules="rulesSeal"
            ref="formSeal"
          >
            <el-form-item label="设置密码：" prop="seal">
              <el-input
                v-model="formSeal.seal"
                placeholder="请输入密码(6~20个字符)"
              ></el-input>
            </el-form-item>
          </el-form>
          <div class="btn">
            <button @click="cancel" class="btn1">取消</button>
            <button @click="confirm" class="btn2">确定</button>
          </div>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible" append-to-body :modal="false">
          <img width="100%" :src="dialogImageUrl" alt="" />
        </el-dialog>
      </main>
    </div>
  </div>
</template>

<script>
import { post } from "@/utils/http.js";
export default {
  data() {
    return {
      form: {
        companyName: "",
        legalRepresentative: "",
        idNumber: "",
        phone: "",
        atype: "",
        code: "",
        seal: "",
      },
      tabList2: [],
      rules: {
        atype: [
          { required: true, message: "请选择活动区域", trigger: "change" },
        ],
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入统一信用代码", trigger: "blur" },
        ],
        legalRepresentative: [
          { required: true, message: "请输入项目负责人", trigger: "blur" },
        ],
        idNumber: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
      },
      rulesSeal: {
        seal: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9.]{6,20}$/,
            message: "请输入6到20个字符",
            trigger: "blur",
          },close
        ],
      },
      fileList: [],
      fileLists: [],
      formSeal: {
        seal: "",
      },
      uploadDialogVisible: false,
      dialogVisible: false,
      dialogImageUrl: "",
      accountID: "",
      aafile: "",
    };
  },
  mounted() {
    post("/loginInfo/queryBodyType")
      .then((res) => {
        if (res.code == 200) {
          this.tabList2 = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  methods: {
    saveCompany() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.accountID = JSON.parse(sessionStorage.getItem("accountID"));
          let {
            companyName,
            legalRepresentative,
            idNumber,
            phone,
            atype,
            code,
          } = this.form;
          let { seal } = this.formSeal;
          console.log("this.formSeal", seal);
          console.log(this.aafile);
          const formData = new FormData();
          formData.append("companyName", companyName);
          formData.append("legalRepresentative", legalRepresentative);
          formData.append("address", idNumber);
          formData.append("phone", phone);
          formData.append("atype", atype);
          formData.append("license", code);
          formData.append("password", seal);
          formData.append("file", this.aafile);
          formData.append("userId", this.accountID);
          console.log("formData", formData);
          post("/loginInfo/firstCompany", formData)
            .then((res) => {
              if (res.code == 200) {
                this.$message.success("已完善企业信息");
                sessionStorage.setItem("admin", res.data.administratorFlag);
                sessionStorage.setItem("companyId", res.data.companyId);
                sessionStorage.setItem("currentUser",JSON.stringify(res.data.currentUser));
                // sessionStorage.setItem("fiveBody",JSON.stringify(res.data.fiveBody));
                this.$router.go(0);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          this.$message.success("保存成功");
        }
      });
    },
    close() {
      this.uploadDialogVisible = false;
      window.sessionStorage.clear()
      this.$router.push("/");
      
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    onChange(file, fileList) {
      this.fileList = fileList;
      this.aafile = file.raw;
      console.log("onChange", file, fileList);
    },
    httpRequest(file) {
      console.log("httpRequest", file, this.fileList);
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      console.log("handleRemove", file, fileList);
    },
    handlePictureCardPreview(file) {
      console.log("handlePictureCardPreview", file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    cancel() {
      this.uploadDialogVisible = false;
      this.$refs.form.resetFields();
      this.$refs.formSeal.resetFields();
    },
    confirm() {
      this.$refs.formSeal.validate((valid) => {
        if (valid == true) {
          this.uploadDialogVisible = false;
          this.fileLists = this.fileList;
          this.abc = this.formSeal.seal;
        }
      });
    },
  },
};
</script>

<style lang="less">
.seal-dialog {
  .el-dialog__body {
    div {
      text-align: center;
    }
    .el-upload--picture-card {
      background: rgba(0, 0, 0, 0.2) !important;
    }
    p {
      margin: 1rem 0;
      text-align: center;
    }
  }
}
</style>
<style lang="less" scoped>
.adminFirst {
  width: 75vw;
  height: 75vh;
  position: fixed;
  margin: auto;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  font-family: PingFangSC-Medium, PingFang SC;
  border-radius: 8px 8px 0px 0px;
  background: linear-gradient(
    360deg,
    rgba(24, 144, 255, 0) 0%,
    rgba(24, 144, 255, 0.2) 100%
  );
  /deep/ .el-form-item__label {
    padding: 0px;
  }
  /deep/ .el-form-item {
    margin-bottom: 24px;
  }
  header {
    width: 100%;
    background: rgba(0, 0, 0, 0.2);
    h1,
    h2 {
      width: 100%;
      font-weight: 500;
      color: #ffffff;
      text-align: center;
    }
    h1 {
      padding-top: 36px;
      height: 36px;
      font-size: 36px;
      line-height: 36px;
    }
    h2 {
      padding: 26px 0;
      height: 20px;
      font-size: 20px;
      line-height: 20px;
    }
  }
  .main-box {
    height: 100%;
    width: 100%;
    padding-top: 87px;
    /deep/ .el-input__inner {
      width: 100%;
    }
    .el-select {
      width: 100%;
    }
    input {
      width: 330px;
      height: 32px;
      opacity: 0.2;
      border: 1px solid #ffffff;
      background-color: transparent;
      input::placeholder {
        color: #fff;
      }
    }
    .box-left {
      width: 50%;
      height: 80%;
      .el-col {
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
        span {
          width: 90px;
          text-align: right;
        }
      }
    }
    .box-right {
      width: 50%;
      height: 80%;
      .el-col {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 20px;
        height: 32px;
      }
    }
    .previewSeal {
      display: flex;

      .sealImg {
        width: 118px;
        height: 118px;
        border-radius: 8px;
        border: 1px dashed #ffffff;
        margin-right: 5px;
        div {
          width: 118px;
          height: 118px;
        }
      }
    }
  }
  .box-footer {
    padding-top: 100px;
    display: flex;
    justify-content: center;
    margin: auto;
  }
  .delete {
    cursor: pointer;
    position: absolute;
    margin: auto;
    top: 0px;
    left: 100%;
    right: 0px;
    bottom: 0px;
    background-color: transparent;
  }
  .plus {
    width: 118px;
    height: 118px;
    opacity: 0.2;
    cursor: pointer;
    text-align: center;
    line-height: 118px;
    border-radius: 8px;
    border: 1px dashed #ffffff;
    i {
      font-size: 32px;
    }
  }
  .btn {
    button {
      width: 114px;
      height: 32px;
      color: white;
      font-size: 14px;
      font-weight: 400;
      box-sizing: border-box;
      margin-right: 40px;
    }
    .btn1 {
      cursor: pointer;
      background: #0084ff;
      border: none;
      color: white;
      margin-right: 20px;
    }
    .btn2 {
      cursor: pointer;
      background: #0084ff;
      border: none;
      color: white;
    }
  }
}
</style>
