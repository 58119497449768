<template>
  <div class="role-warp">
    <div class="header">
      <div class="item">
        <span>用户账号：</span>
        <el-input
          placeholder="请输入用户 ID/账号"
          size="small"
          v-model="phone"
        ></el-input>
      </div>
      <div class="item">
        <span>用户名：</span>
        <el-input
          placeholder="请输入用户名"
          size="small"
          v-model="username"
        ></el-input>
      </div>
      <div class="item">
        <span>注册时间：</span>
        <el-date-picker
          size="small"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          v-model="Time"
          @change="timeChange"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="btn-warp">
        <el-button size="small" type="primary" @click="query">查 询</el-button>
        <el-button size="small" plain class="reset" @click="reset"
          >重 置</el-button
        >
      </div>
      <el-button size="small" type="primary" @click.native="addUser()"
        >创建用户<i class="el-icon-circle-plus-outline"></i
      ></el-button>
    </div>
    <div class="content">
      <!-- <div class="btn-warp">
        <el-button
          size="small"
          type="primary"
          :id="outTableData.id"
          :name="outTableData.name"
          >导出数据</el-button
        >
      </div> -->
      <el-table
        id="outTable"
        :data="tableData"
        style="width: 100%"
        :row-class-name="tableRowClassName"
      >
        <el-table-column type="selection" width="80"> </el-table-column>
        <el-table-column prop="id" label="用户ID" width="180">
        </el-table-column>
        <el-table-column prop="phone" label="用户账号" width="180">
        </el-table-column>
        <el-table-column prop="realname" label="用户名"> </el-table-column>
        <el-table-column prop="roles" label="所属角色"></el-table-column>
        <el-table-column
          prop="corporateName"
          label="所属公司"
        ></el-table-column>
        <el-table-column prop="createTime" label="注册时间"> </el-table-column>
        <el-table-column prop="statusName" label="账户启用状态">
          <template slot-scope="scope">
            <el-switch
              :disabled="
                scope.row.roleCode.indexOf('admin') != '-1' ? true : false
              "
              v-model="scope.row.statusName"
              inactive-color="rgba(8, 16, 28, 0.50)"
              active-text="启用"
              inactive-text="禁用"
              @change="switchChange($event, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="editUser(scope.row)">编辑</el-button>
            <el-button
              type="text"
              style="color: rgba(255, 75, 75, 1)"
              @click="deleteUser(scope.row)"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="prev, pager, next"
        :total="total"
        :current-page="pageNumber"
        @current-change="currentChange"
      >
      </el-pagination>
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-size="100"
        layout="total, prev, pager, next"
        :total="1000"
      >
      </el-pagination> -->
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
      custom-class="black-theme"
      :title="userCreate ? '创建用户' : '编辑用户'"
      :modal="false"
      :close-on-click-modal="false"
      @close="resetForm"
    >
      <el-form label-width="140px" :model="form" :rules="rules" ref="form">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户账号：" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号"
              ></el-input>
            </el-form-item>
            <el-form-item label="用户名：" prop="realname">
              <el-input
                v-model="form.realname"
                placeholder="请输入用户名"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属角色：" prop="role">
              <el-cascader
                v-model="form.role"
                :options="companyAndRole"
                @change="handleChange"
              ></el-cascader>
              <!-- <a-cascader :options="companyAndRole" :default-value="[0,2]" @change="handleChange" /> -->
            </el-form-item>
            <!-- <template v-if="userCreate"> -->
            <el-form-item
              :label="userCreate ? '密码' : '修改密码'"
              prop="password"
              :required="userCreate"
            >
              <el-input
                placeholder="请输入密码"
                type="password"
                v-model="form.password"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="确认密码："
              prop="checkPass"
              :required="userCreate"
            >
              <el-input
                placeholder="请确认密码"
                type="password"
                v-model="form.checkPass"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <!-- </template> -->
            <!-- <template v-else>
              <el-form-item :label="changeValue" prop="password">
                <el-input
                  placeholder="如需修改请输入新密码"
                  type="password"
                  v-model="form.password"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="确认密码：" prop="checkPass">
                <el-input
                  placeholder="请确认修改密码"
                  type="password"
                  v-model="form.checkPass"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
            </template> -->
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false;
            resetForm();
          "
          size="small"
          >取 消</el-button
        >
        <el-button size="small" type="primary" @click="createUser"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { post, posts, get } from "@/utils/http.js";
export default {
  // props: ["companyAndRole"],
  data() {
    const regTel =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
    const handlePhoneChange = (rule, value, callback) => {
      if (!regTel.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        this.handlePhoneCheck(rule, value, callback);
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        if (this.userCreate) {
          callback(new Error("请输入密码"));
        } else {
          callback();
        }
      } else {
        if (this.form.checkPass !== "") {
          this.$refs.form.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        // callback(new Error("请再次输入密码"));
        if (this.userCreate) {
          callback(new Error("请再次输入密码"));
        } else {
          callback();
        }
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      username: "",
      phone: "",
      endTime: "",
      startTime: "",
      Time: "",
      total: 10,
      pageNumber: 1,
      pageSize: 10,
      dialogVisible: false,
      tableData: [],
      companyAndRole: [],
      outTableData: {
        id: "outTable",
        name: "交易数据",
      },
      userCreate: true,
      form: {
        phone: "",
        realname: "",
        role: "",
        password: "",
        checkPass: "",
      },
      changeValue: "",
      selects: "",
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { validator: handlePhoneChange, trigger: "blur" },
        ],
        realname: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        role: [{ required: true, message: "请选择用户角色", trigger: "blur" }],
        password: [
          // { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        checkPass: [
          // { required: true, message: "请再次输入密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" },
        ],
      },
      roleList: [
        {
          value: "aaa",
          label: "宝业集团",
          children: [
            {
              value: "basic",
              label: "管理员",
            },
            {
              value: "form",
              label: "Form",
            },
            {
              value: "data",
              label: "Data",
            },
            {
              value: "notice",
              label: "Notice",
            },
          ],
        },
        {
          value: "zujian",
          label: "中天建设",
          children: [
            {
              value: "basic",
              label: "Basic",
            },
            {
              value: "form",
              label: "Form",
            },
            {
              value: "data",
              label: "Data",
            },
            {
              value: "notice",
              label: "Notice",
            },
          ],
        },
      ],
    };
  },
  created() {
    this.init(1);
  },
  methods: {
    selectCompanyAndRole(companyId) {
      if (sessionStorage.getItem("companyId") != "undefined") {
        get("loginInfo/selectCompanyAndRole", {
          companyId,
          // companyId: this.admin == 1 ? 0 : ~~sessionStorage.getItem("companyId"),
        }).then((res) => {
          if (res.code == 200) {
            this.companyAndRole = res.data;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
    handleChange() {
      console.log("this.form.role", this.form.role);
    },
    deleteUser(row) {
      const title = "系统提示";
      const content = "是否删除该用户？";
      this.$alert(content, title, {
        customClass: "customClass",
        showCancelButton: true,
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            post("/loginInfo/deleteUser", {
              id: row.id,
              companyId: row.companyId,
            }).then((res) => {
              if (res.code == 200) {
                this.init(this.pageNumber);
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            return false;
          }
        },
      });
    },
    // 编辑用户弹窗打开
    editUser(user) {
      console.log(user);
      this.selectCompanyAndRole(user.companyId);
      this.userCreate = false;
      this.selects = "编辑用户";
      this.changeValue = "修改密码：";
      this.form.phone = user.phone;
      this.form.realname = user.realname;
      this.form.id = user.id;
      get("loginInfo/selectUser", { userId: user.id })
        .then((res) => {
          this.form.role = res.data.role;
          this.dialogVisible = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 重置表单
    resetForm() {
      this.$refs.form.resetFields();
      this.form = {
        phone: "",
        realname: "",
        role: "",
        password: "",
        checkPass: "",
      };
    },
    query() {
      this.init(1);
    },
    // 确认创建用户按钮
    createUser() {
      this.$refs.form.validate((valid) => {
        console.log(valid);
        if (valid) {
          if (this.userCreate) {
            post("loginInfo/savePrivateUser", this.form).then((res) => {
              if (res.code == 200) {
                this.$refs.form.resetFields();
                this.dialogVisible = false;
                this.init(this.pageNumber);
                this.$message.success("用户创建成功");
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            post("loginInfo/savePrivateUser", this.form).then((res) => {
              if (res.code == 200) {
                this.$refs.form.resetFields();
                this.dialogVisible = false;
                this.init(this.pageNumber);
                this.$message.success("用户编辑成功");
              } else {
                this.$message.error(res.msg);
              }
            });
          }
        }
      });
    },

    reset() {
      (this.username = ""),
        (this.phone = ""),
        (this.endTime = ""),
        (this.startTime = ""),
        (this.Time = "");
      console.log(2);
    },
    timeChange(value) {
      (this.startTime = value[0]),
        (this.endTime = value[1]),
        console.log(value);
    },
    // 创建用户弹窗打开
    addUser() {
      this.selectCompanyAndRole(
        sessionStorage.getItem("admin") == 1
          ? 0
          : ~~sessionStorage.getItem("companyId")
      );
      this.selects = "创建用户";
      this.userCreate = true;
      this.changeValue = "密码：";
      this.dialogVisible = true;
    },
    // tabel开关
    switchChange(status, data) {
      post("/loginInfo/updateUserStatus", {
        id: data.id,
        statusName: status,
      }).then((res) => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        }
      });
    },
    // 正则检验过后检验该手机号是否注册   未注册的通过
    handlePhoneCheck(rule, value, callback) {
      if (this.userCreate) {
        posts("/User/checkPhone", { phone: value }).then((res) => {
          if (res.code == 0) {
            callback();
          } else {
            callback(new Error(res.msg));
          }
        });
      } else {
        callback();
      }
    },
    currentChange(pageNumber) {
      this.init(pageNumber);
      this.pageNumber = pageNumber;
    },
    init(pageNumber) {
      const params = {
        pageNumber,
        companyId: sessionStorage.getItem("companyId"),
        realname: this.username,
        phone: this.phone,
        endTime: this.endTime,
        startTime: this.startTime,
        pageSize: this.pageSize,
        roles: sessionStorage.getItem("admin") == "1" ? "super_admin" : "",
      };
      post("/loginInfo/queryPrivateUsers", params).then((res) => {
        this.total = res.total;
        // this.total=50
        this.tableData = res.arrayList;
      });
    },
    tableRowClassName({ rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "warning-row";
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="less">
.el-cascader {
  width: 100%;
  .el-input__suffix {
    right: 20px;
  }
}
.customClass {
  padding: 0 0 20px;
  border: 1px solid #666666;
  background-color: rgba(0, 0, 0, 0.8) !important;
  .el-message-box__header {
    .el-message-box__title {
      color: #fff !important;
    }
    // background-color: #0080ff !important;
  }
  .el-message-box__content {
    padding: 20px;
    color: #dbdbdb !important;
  }
}
.black-theme {
  .el-input__inner {
    color: #fff !important;
    background: rgba(0, 0, 0, 0) !important;
  }
}
.role-warp {
  width: 75vw;
  height: 75vh;
  color: #fff;
  position: absolute;
  .header {
    height: 80px;
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    padding: 24px 32px;
    box-sizing: border-box;
    border-radius: 8px 8px 0 0;
    background: rgba(24, 144, 255, 0.2);
    .item {
      width: 350px;
      display: flex;
      align-items: center;
      span {
        width: 6rem;
      }
      .el-input__inner {
        color: #fff !important;
        background: rgba(0, 0, 0, 0) !important;
        // width: calc(100% - 1rem);
        .el-range-separator {
          color: #fff !important;
        }
        .el-range-input {
          background: rgba(0, 0, 0, 0);
        }
      }
    }
    .btn-warp {
      margin-left: 20px;
      .reset {
        color: #fff;
        background: rgba(0, 0, 0, 0);
      }
    }
  }
  .content {
    width: 100%;
    height: calc(100% - 80px);
    background: linear-gradient(
      to bottom,
      rgba(24, 144, 255, 0.1),
      rgba(0, 0, 0, 0)
    );
    .btn-warp {
      padding: 12px 32px;
    }
    .el-table {
      background-color: transparent; // 背景透明
      th,
      tr,
      td {
        text-align: center;
        background-color: transparent; // 背景透明
        border: none;
        color: #fff; // 修改字体颜色
        font-family: Source Han Sans CN Normal, Source Han Sans CN Normal-Normal;
      }
      .warning-row {
        background: rgba(0, 0, 0, 0.1) !important;
      }
      tr:hover > td {
        background: rgba(24, 144, 255, 0.2) !important;
      }
      .el-switch__label {
        color: #fff;
      }
      .is-active {
        color: #0080ff !important;
      }
    }

    // 去掉最下面的那一条线
    .el-table::before {
      height: 0px;
    }

    .el-pagination {
      text-align: right;
      margin: 20px 20px 0 0;
      .btn-prev,
      .el-pager,
      .btn-quicknext,
      .btn-quickprev,
      .btn-next {
        color: #fff;
        background: rgba(0, 0, 0, 0) !important;
        li {
          background: rgba(0, 0, 0, 0) !important;
        }
      }
    }
  }
}
</style>
