<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
// import autofit from "autofit.js";

export default {
  name: "App",
  mounted() {
    // autofit.init(
    //   {
    //     // designHeight: 1289,
    //     // designWidth: 2560,
    //     designHeight: 929,
    //     designWidth: 1920,
    //     resize: true,
    //   },
    //   false
    // ); // 可关闭控制台运行提示输出
  },
};
</script>
<style lang="less">
#app {
  // height: 100%;
  // width: 100%;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
</style>
