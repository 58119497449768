import axios from 'axios'
import {
    publicApi
} from "@/utils/http";
import router from '@/router'
import {
    Message
} from 'element-ui';
// import { Message } from 'element-ui';
// import _this from "@/main.js";
// import { posts } from "@/utils/http.js"
// import router from '@/router'
// 中铁十四局
// const BASE_URL = 'https://ztssxtptapi.abuildingcloud.com/'
// const servicesPath = "https://ztssyzlapi.abuildingcloud.com" //外网
// const yzlUrl = "https://ztssyzl.abuildingcloud.com/redirectUrl" //外网
// const xtptUrl = "https://ztssxtpt.abuildingcloud.com/redirect_url" //外网
// const servicesPath2 = "https://ztssxtptapi.abuildingcloud.com/"
// const DDredirectUrl = "https://ztssmultiport.abuildingcloud.com/DDredirect_uri"
// const newPayservicesPath = 'https://sk.abuildingcloud.com/'
// const scslUrl = "https://ztssscsl.abuildingcloud.com/redirectUrl" //跳转地址
// const scslLoginUrl = "https://ztssscslapi.abuildingcloud.com/api/User/Login" //登录接口
//中建八局
// const scslUrl = "https://zjbjscsl.abuildingcloud.com/#/login/"         //外网
// const scslLoginUrl = "https://zjbjscslapi.abuildingcloud.com/api/User/Login"         //外网
// const BASE_URL = 'http://zjbjxtptapi.abuildingcloud.com/'
// const servicesPath="http://zjbjyzlapi.abuildingcloud.com"          //外网
// const yzlUrl = "http://zjbjyzl.abuildingcloud.com/redirectUrl"         //外网
// const xtptUrl = "http://zjbjxtpt.abuildingcloud.com/redirect_url"         //外网
// 上海公司
// const scslUrl = "https://sszjscsl.abuildingcloud.com/#/login/"         //外网
// const scslLoginUrl = "https://sszjscslapi.abuildingcloud.com/api/User/Login"         //外网
// const BASE_URL = 'https://sszjxtptapi.abuildingcloud.com/'
// const servicesPath="https://sszjyzlapi.abuildingcloud.com"          //外网
// const yzlUrl = "https://sszjyzl.abuildingcloud.com/redirectUrl"         //外网
// const xtptUrl = "https://sszjxtpt.abuildingcloud.com/redirect_url"         //外网
// 测试环境
// const BASE_URL = 'http://192.168.10.129:8610/'
// const servicesPath="http://192.168.10.129:8086"          //外网
// const servicesPath2 = "http://192.168.10.129:8610/"  
// const yzlUrl = "http://192.168.10.129:8082/redirectUrl"         //外网
// const xtptUrl = "http://192.168.10.129:8081/redirect_url"         //外网
// const newPayservicesPath = 'http://192.168.10.129:8005/'
// const DDredirectUrl = "http://192.168.10.129:8082/DDredirect_uri"

// const BASE_URL = 'https://coordination.abuildingcloud.com/'
// const servicesPath="https://cloud.abuildingcloud.com"          //外网
// const servicesPath2="https://cloud.abuildingcloud.com"          //外网
// const yzlUrl = "https://yzl.abuildingcloud.com/redirectUrl"         //外网
// const xtptUrl = "https://xtpt.abuildingcloud.com/redirect_url"         //外网
// const newPayservicesPath = 'https://sk.abuildingcloud.com/'
// const DDredirectUrl = "https://multiport.abuildingcloud.com/DDredirect_uri"         //跳转地址
// const scslUrl = "http://newscsl.abuildingcloud.com/redirectUrl" //新实测地址



const scslLoginUrl = "https://mbapi.abuildingcloud.com/api/User/Login" //登录接口
// const scslUrl = "https://scsl.abuildingcloud.com/#/login/" //老实测地址

// 老版分离
// const scslUrl = "http://192.168.10.115:8083/redirectUrl" //本地实测地址
// const DDredirectUrl = "http://192.168.10.124:8081/DDredirect_uri"
// const newPayservicesPath = 'http://192.168.10.226:8005/'
// const BASE_URL = 'http://192.168.10.241:8610' //佳军：172 小谢：223 贵江：120
// const servicesPath = "http://192.168.10.241:8082/"
// const servicesPath2 = "http://192.168.10.241:8610/"
// const yzlUrl = "http://192.168.10.124:8081/redirectUrl"
// const xtptUrl = "http://192.168.10.224:8001/redirect_url"

// // 新版整合
// const DDredirectUrl = "http://192.168.10.124:8081/DDredirect_uri"
// const newPayservicesPath = 'http://192.168.10.226:8005/'
// const servicesPath2 = "http://192.168.10.223:8610/"
// const servicesPath = "http://192.168.10.241:8099/"
// const BASE_URL = 'http://192.168.10.241:8099' //佳军：172 小谢：223 贵江：120
// const yzlUrl = "http://192.168.10.124:8080/redirectUrl"
// const xtptUrl = "http://192.168.10.124:8001/redirect_url"
// const scslUrl = "http://192.168.10.124:8082/redirectUrl" //本地实测地址

// 新版整合
const DDredirectUrl = process.env.VUE_APP_DDredirectUrl
const newPayservicesPath = process.env.VUE_APP_newPayservicesPath
const servicesPath2 = process.env.VUE_APP_servicesPath2
const servicesPath = process.env.VUE_APP_servicesPath
const BASE_URL = process.env.VUE_APP_BASE_URL
const yzlUrl = process.env.VUE_APP_yzlUrl
const xtptUrl = process.env.VUE_APP_xtptUrl
const scslUrl = process.env.VUE_APP_scslUrl

// 后端接口
const services = axios.create({
    baseURL: servicesPath, //调试
    timeout: 50000
})
const service = axios.create({
    baseURL: BASE_URL, //调试
    timeout: 50000
})
// 添加请求拦截器
services.interceptors.request.use(function (config) {
    let openUrl = config.url == '/User/checkPhone' ? false : true
    if (sessionStorage.getItem('authorization') && openUrl) {
        config.headers.token = sessionStorage.getItem('authorization')
    }
    // if (sessionStorage.getItem('zcToken')) {
    //     config.headers.Authorization = sessionStorage.getItem('zcToken')
    // }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 做一个响应拦截 并定义一个如果失败的提示
services.interceptors.response.use(function (response) {
    const res = response.data
    // console.log('响应拦截',response,)
    // if (res.code == 401 && sessionStorage.getItem('authorization')) {
    //     posts('User/logout').then(() => {
    //         clearInterval(_this.$countDown)
    //         _this.$cookies.remove("U_SESSION");
    //         window.sessionStorage.clear();
    //         router.push("/");
    //         console.log('响应拦截的提示')
    //         Message({
    //             type: "error",
    //             message: res.msg || "token无效，请重新登录"
    //         })
    //     })

    // }
    return res
}, function (error) {
    return Promise.reject(error);
});




service.interceptors.request.use(function (config) {
    if (sessionStorage.getItem('authorization')) {
        config.headers.token = sessionStorage.getItem('authorization')
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});
let flag = true // JS请求闭包，拦截用
service.interceptors.response.use(function (response) {
    const res = response.data
    const msg = res.code + '：' + res.msg || "Error"
    if (res.code == 401 && sessionStorage.getItem('authorization') && flag) {
        flag = false
        publicApi.post("User/logout").then(() => {
            window.sessionStorage.clear();
            router.push("/");
            // console.log('响应拦截的提示')
            Message.error(msg)
            setTimeout(() => {
                flag = true
            }, 1000);
        })
    } else {
        if (res.code !== 200) {
            Message.error(msg)
        }
    }
    return res
}, function (error) {
    return Promise.reject(error);
});
// 导出去方便封装

export {
    services,
    servicesPath,
    servicesPath2,
    yzlUrl,
    xtptUrl,
    scslUrl,
    service,
    scslLoginUrl
}
// 新订单相关
const newPayservices = axios.create({
    baseURL: newPayservicesPath,
    timeout: 5000
})
// 添加请求拦截器
newPayservices.interceptors.request.use(function (config) {
    if (sessionStorage.getItem('authorization')) {
        config.headers.token = sessionStorage.getItem('authorization')
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

// 做一个响应拦截 并定义一个如果失败的提示
newPayservices.interceptors.response.use(function (response) {
    const res = response.data
    return res
}, function (error) {
    return Promise.reject(error);
});
export {
    newPayservices,
    DDredirectUrl
}