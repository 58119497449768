<template>
  <div>
    <div class="warp">
      <div class="warp-top">
        <div class="top-left">{{ currentDw }}</div>
        <div class="top-right">{{ currentCompany }}</div>
      </div>
      <div class="warp-center">
        <!-- tab头部 -->
        <el-row class="w-full tabs flex">
          <el-col
            class="tab-pane"
            v-for="(item, index) in tabList"
            :key="index"
            :class="num == index ? 'tab-pane-active' : ''"
            @click.native="viewChange(index)"
            >{{ item.name }}</el-col
          >
        </el-row>
        <div v-if="selecterBasic" class="center-main w-full">
          <div class="company-warp w-full">
            <div
              v-for="(item, index) in companyList"
              :key="index"
              class="company-item"
              @click="editCompany(item.id)"
            >
              <span>{{ item.companyName }}</span>
              <i class="el-icon-close" @click.stop="deleteCompany(item.id)"></i>
            </div>
            <div class="company-item" @click="addCompany()">
              <i class="el-icon-plus" style="fong-size: 36px"></i>
            </div>
            <div style="width: 403px"></div>
          </div>

          <!-- <div class="add">+</div> -->
        </div>

        <div v-else class="center-main2">
          <div class="main2-box">
            <el-form
              label-width="140px"
              :model="form"
              :rules="rules"
              ref="form"
            >
              <el-row>
                <el-col :span="12">
                  <el-form-item label="公司名称：" prop="companyName">
                    <el-input v-model="form.companyName"></el-input>
                  </el-form-item>
                  <el-form-item label="公司简称：" prop="companyAbbreviation">
                    <el-input v-model="form.companyAbbreviation"></el-input>
                  </el-form-item>
                  <el-form-item label="联系电话：" prop="phone">
                    <el-input v-model="form.phone"></el-input>
                  </el-form-item>
                  <el-form-item label="公司法人：" prop="legalRepresentative">
                    <el-input v-model="form.legalRepresentative"></el-input>
                  </el-form-item>
                  <el-form-item label="统一信用代码：" prop="license">
                    <el-input v-model="form.license"></el-input>
                  </el-form-item>
                  <el-form-item label="主体类型：" prop="type" required>
                    <el-select v-model="form.type" disabled>
                      <el-option
                        v-for="item in tabList2"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="证件号码：" prop="idCard">
                    <el-input v-model="form.idCard"></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="selectId == ''"
                    label="性别："
                    prop="gender"
                  >
                    <el-select v-model="form.gender">
                      <el-option label="男" value="0"></el-option>
                      <el-option label="女" value="1"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    v-if="selectId == ''"
                    label="管理员真实姓名："
                    prop="realname"
                  >
                    <el-input v-model="form.realname"></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="selectId == ''"
                    label="管理员账号："
                    prop="username"
                  >
                    <el-input v-model="form.username"></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="selectId == ''"
                    label="管理员密码："
                    prop="password"
                  >
                    <el-input v-model="form.password"></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="selectId == ''"
                    label="管理员密码确认："
                    prop="checkPassword"
                  >
                    <el-input v-model="form.checkPassword"></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="selectId !== ''"
                    label="创建人："
                    required
                  >
                    <el-input v-model="form.createBy" disabled></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="selectId !== ''"
                    label="创建手机号："
                    required
                  >
                    <el-input v-model="form.createByPhone" disabled></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
            <div class="box-footer">
              <button class="back btn" @click="close">返回</button>
              <button @click="saveCompany()" class="preserve btn">保存</button>
            </div>
            <i
              @click="close"
              style="font-size: 24px"
              class="el-icon-circle-close delete"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post, posts, get, del } from "@/utils/http.js";
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    const regTel =
      /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/; // 11位手机号正则验证
    const handleChange = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!regTel.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          this.handleCheck(rule, value, callback);
        }
      }
    };
    const phoneValid = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else {
        if (!regTel.test(value)) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      }
    };
    const licenseValid = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入统一信用代码"));
      } else {
        if (value.length !== 18) {
          callback(new Error("统一信用代码长度为18位"));
        } else {
          callback();
        }
      }
    };
    return {
      isAdminFirst: true,
      selectId: "",
      currentDw: "",
      currentCompany: "",
      selecterBasic: true,
      form: {
        companyName: "",
        license: "",
        legalRepresentative: "",
        phone: "",
        type: "1",
        companyAbbreviation: "",
        idCard: "",
        realname: "",
        username: "",
        password: "123456",
        checkPassword: "123456",
        gender: "0",
      },
      formSeal: {
        seal: "",
      },
      fileList: [],
      fileLists: [],
      abc: "",
      aafile: "",
      dialogImageUrl: "",
      dialogVisible: false,
      uploadDialogVisible: false,
      disabled: false,
      rules: {
        companyName: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
        ],
        license: [
          { required: true, message: "请输入统一信用代码", trigger: "blur" },
          { validator: licenseValid, trigger: "blur" },
        ],
        legalRepresentative: [
          { required: true, message: "请输入公司法人", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
          { validator: phoneValid, trigger: "blur" },
        ],
        companyAbbreviation: [
          { required: true, message: "请输入公司简称", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "请输入证件号码", trigger: "blur" },
        ],
        realname: [
          { required: true, message: "请输入管理员姓名", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入管理员账号", trigger: "blur" },
          { validator: handleChange, trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9.]{6,20}$/,
            message: "请输入6到20个字符",
            trigger: "blur",
          },
        ],
        checkPassword: [
          { required: true, message: "请确认管理员密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "change" }],
      },
      rulesSeal: {
        seal: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9.]{6,20}$/,
            message: "请输入6到20个字符",
            trigger: "blur",
          },
        ],
      },
      num: 0,
      tabList: ["建设单位", "监理单位", "设计单位", "勘察单位", "分包单位"],
      companyList: [],
      page: {
        pageNumber: 1,
        pageSize: 100,
      },
    };
  },
  created() {},
  mounted() {
    this.currentDw = JSON.parse(sessionStorage.getItem("fiveBody")).name;
    this.currentCompany = JSON.parse(
      sessionStorage.getItem("companyInfo")
    ).companyName;
    console.log(
      "JSON.parse(sessionStorage.getItem=====>",
      JSON.parse(sessionStorage.getItem("fiveBody"))
    );
    this.getDwType();
  },
  methods: {
    deleteCompany(id) {
      const title = "系统提示";
      const content = "是否删除该公司？";
      this.$alert(content, title, {
        customClass: "customClass",
        showCancelButton: true,
        confirmButtonText: "删除",
        cancelButtonText: "取消",
        callback: (action) => {
          if (action == "confirm") {
            del("/loginInfo/deleteCompany", { companyId: id }).then((res) => {
              if (res.code == 200) {
                this.getCompanyData(this.tabList[this.num].id);
              } else {
                this.$message.error(res.msg);
              }
            });
          } else {
            this.$message("已取消");
          }
        },
      });
    },
    getDwType() {
      const that = this;
      post("/loginInfo/queryBodyType").then((res) => {
        if (res.code == 200) {
          that.tabList = res.data;
          const arr = res.data;
          let brr = [];
          for (var i = 0; i < arr.length; i++) {
            brr[i] = {};
            brr[i].value = arr[i].id;
            brr[i].label = arr[i].name;
          }
          console.log("brr======>", brr);
          that.tabList2 = brr;
          console.log(that.tabList2);
          that.getCompanyData("1");
        }
      });
    },
    onChange(file, fileList) {
      this.fileList = fileList;
      this.aafile = file.raw;
      console.log("onChange", file, fileList);
    },
    viewChange(index) {
      console.log(index);
      this.num = index;
      this.close();
      this.getCompanyData(this.tabList[index].id);
    },
    getCompanyData(id) {
      const that = this;
      that.companyList = [];
      const query = {};
      query.type = id;
      query.pageNumber = that.page.pageNumber;
      query.pageSize = that.page.pageSize;
      post("/loginInfo/queryCompany", query).then((res) => {
        console.log("res=====>", res);
        if (res.code == 200) {
          that.companyList = res.data;
        }
      });
    },
    editCompany(id) {
      // post("/loginInfo/selectCompany", { id }).then((res) => {
      get("/loginInfo/getCompayInfo", { companyId: id }).then((res) => {
        console.log(res.data.id);
        this.selectId = id;
        console.log(" this.selectId", this.selectId);
        this.selecterBasic = false;
        this.form = res.data.companyInfo;
        this.form.createBy = res.data.currentUser.realname;
        this.form.createByPhone = res.data.currentUser.phone;
        // this.form.companyName = companyName;
        // this.form.atype = Number(type);
        // this.form.license = license;
        // this.form.legalRepresentative = legalRepresentative;
        // this.form.idNumber = address;
        // this.form.phone = phone;
        // this.fileLists = url ? [url] : [];
        // this.fileList = url ? [url] : [];
        // console.log("this.fileLists=====>", this.fileLists);
      });
    },
    addCompany() {
      this.selectId = "";
      this.form.type = this.tabList[this.num].id;
      this.selecterBasic = false;
    },
    saveCompany() {
      this.$refs.form.validate((valid) => {
        if (valid == true) {
          console.log("this.selectId", this.selectId);
          const {
            companyName,
            license,
            legalRepresentative,
            phone,
            type,
            companyAbbreviation,
            idCard,
            realname,
            username,
            password,
            gender,
          } = this.form;
          const params = {
            companyInfo: {
              companyName,
              license,
              legalRepresentative,
              phone,
              type,
              companyAbbreviation,
              idCard,
            },
          };
          // 有了selectId就是编辑
          if (this.selectId) {
            params.companyInfo.id = this.selectId;
            params.adminUser = {};
          } else {
            params.adminUser = {
              realname,
              username,
              password,
              phone: username,
              gender,
              type: 4,
            };
          }
          post(
            this.selectId
              ? "/loginInfo/editPrivateCompany"
              : "loginInfo/savePrivateCompany",
            params
          )
            .then((res) => {
              if (res.code == 200) {
                this.getCompanyData(this.tabList[this.num].id);
                this.selecterBasic = true;
                this.$message.success("保存成功");
              } else {
                this.$message.error(res.msg);
              }
              console.log(res);
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {});
        }
      });
    },
    cancel() {
      this.uploadDialogVisible = false;
      // this.$refs.form.resetFields();
      this.$refs.formSeal.resetFields();
    },
    confirm() {
      this.$refs.formSeal.validate((valid) => {
        if (valid == true) {
          // this.$refs.formSeal.resetFields();
          this.uploadDialogVisible = false;
          this.fileLists = this.fileList;
          this.abc = this.formSeal.seal;
          console.log("abc==========", this.abc);
        }
      });
    },
    close() {
      this.form = {
        companyName: "",
        license: "",
        legalRepresentative: "",
        phone: "",
        type: 1,
        companyAbbreviation: "",
        idCard: "",
        realname: "",
        username: "",
        password: "123456",
        checkPassword: "123456",
        gender: "0",
      };
      this.selecterBasic = true;
      this.fileLists = [];
    },
    handleDownload(file) {
      console.log(file);
    },
    handleCheck(rule, value, callback) {
      posts("/User/checkPhone", { phone: value }).then((res) => {
        if (res.code == 0) {
          callback();
        } else {
          callback(new Error(res.msg));
        }
      });
    },
  },
};
</script>
<style lang="less">
.el-input.is-disabled .el-input__inner {
  background: rgba(0, 0, 0, 0) !important;
}
.seal-dialog {
  .el-dialog__body {
    div {
      text-align: center;
    }
    .el-upload--picture-card {
      background: rgba(0, 0, 0, 0.2) !important;
    }
    p {
      margin: 1rem 0;
      text-align: center;
    }
  }
}
.btn {
  button {
    width: 114px;
    height: 32px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    box-sizing: border-box;
  }
  .btn1 {
    cursor: pointer;
    background: #0084ff;
    border: none;
    color: white;
    margin-right: 20px;
  }
  .btn2 {
    cursor: pointer;
    background: #0084ff;
    border: none;
    color: white;
  }
}
</style>
<style lang="less" scoped>
/deep/.el-input__inner {
  color: #fff !important;
  background: rgba(0, 0, 0, 0) !important;
}
/deep/.el-form-item__label {
  color: #fff !important;
}
.plus {
  width: 118px;
  height: 118px;
  opacity: 0.2;
  cursor: pointer;
  text-align: center;
  line-height: 118px;
  border-radius: 8px;
  border: 1px dashed #ffffff;
  i {
    font-size: 32px;
  }
}
.warp {
  width: 75vw;
  height: 75vh;
  font-family: PingFangSC-Medium, PingFang SC;
  .warp-top {
    width: 100%;
    height: 110px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .top-left {
      width: 30%;
      height: 110px;
      font-size: 24px;
      font-weight: 500;
      line-height: 110px;
      background: url(@/assets/img/bg1.png) no-repeat center;
      background-size: 100% 100%;
      text-align: center;
      border-radius: 5px 0px 0px 5px;
    }
    ::v-deep .top-left {
      color: #ffffff;
    }
    ::v-deep .top-right {
      color: #ffffff;
    }
    .top-right {
      width: 70%;
      height: 110px;
      font-size: 24px;
      font-weight: 500;
      line-height: 110px;
      padding-left: 64px;
      background: linear-gradient(
        to right,
        rgba(24, 144, 255, 0.2),
        rgba(0, 0, 0, 0.2) 100%
      );
    }
  }
  .warp-center {
    position: relative;
    width: 100%;
    background: linear-gradient(
      360deg,
      rgba(24, 144, 255, 0) 0%,
      rgba(24, 144, 255, 0.2) 100%
    );
    height: 627px;
    margin-top: 30px;
    .tabs {
      .tab-pane {
        // width: 20%;
        cursor: pointer;
        text-align: center;
        line-height: 80px;
        height: 80px;
        background: rgba(24, 144, 255, 0.05);
        border-radius: 8px 8px 0px 0px;
        transition: all 0.3s;
      }
      .tab-pane:hover {
        background: rgba(24, 144, 255, 0.2);
      }
      .tab-pane-active {
        background: rgba(24, 144, 255, 0.2);
      }
    }
    .center-top {
      display: flex;
      margin-bottom: 40px;
      div {
        width: 280px;
        height: 80px;
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 33px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .center-main {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      padding: 0px 48px;
      box-sizing: border-box;
      justify-content: flex-start;
      align-items: center;
      .company-warp {
        text-align: center;
        margin-bottom: 40px;
        margin-right: 40px;
        font-size: 18px;
        font-weight: 400;
        // // margin-right: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        .company-item {
          position: relative;
          cursor: pointer;
          margin-top: 2vh;
          width: 30%;
          height: 94px;
          background: rgba(24, 144, 255, 0.2);
          line-height: 94px;
          transition: all 0.3s;
          .el-icon-close {
            display: none;
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
            transition: all 0.3s;
          }
        }
        .company-item:hover {
          .el-icon-close {
            display: inline-block !important;
          }
          background: rgba(24, 144, 255);
        }
      }
    }
    .center-main2 {
      width: 80%;
      height: 80%;
      position: absolute;
      margin: auto;
      top: 70px;
      left: 20px;
      right: 20px;
      bottom: 0px;
      .main2-box {
        height: 100%;
        width: 100%;
        padding-top: 50px;
        .el-select {
          width: 100%;
        }
        // background: linear-gradient(180deg, rgb(19, 18, 18) 0%, rgba(0, 0, 0, 0) 100%);
        input {
          width: 330px;
          height: 32px;
          opacity: 0.2;
          border: 1px solid #ffffff;
          background-color: transparent;
          input::placeholder {
            color: #fff;
          }
        }
        .box-left {
          width: 50%;
          height: 80%;
          .el-col {
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 20px;
            span {
              width: 90px;
              text-align: right;
            }
          }
        }
        .box-right {
          width: 50%;
          height: 80%;
          .el-col {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            margin-bottom: 20px;
            height: 32px;
          }
        }
        .previewSeal {
          display: flex;

          .sealImg {
            width: 118px;
            height: 118px;
            border-radius: 8px;
            border: 1px dashed #ffffff;
            margin-right: 5px;
            div {
              width: 118px;
              height: 118px;
            }
          }
        }
      }
      .box-footer {
        display: flex;
        justify-content: center;
        // position: absolute;
        margin: auto;
        // top: 60%;
        // left: 38%;
        // bottom: 0px;
        // right: 0px;
        .btn {
          cursor: pointer;
          width: 114px;
          height: 32px;
          background: #0084ff;
          border: none;
          color: white;
          margin-right: 20px;
        }
      }
      .delete {
        cursor: pointer;
        position: absolute;
        margin: auto;
        top: 0px;
        left: 100%;
        right: 0px;
        bottom: 0px;
        background-color: transparent;
      }
    }
    .el-table::before {
      height: 0px;
    }
  }
  //图片上传代码
  .avatar-uploader .el-upload {
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    border: 1px solid;
    font-size: 28px;
    color: #8c939d;
    width: 150px;
    height: 150px;
    line-height: 150px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .el-upload--picture-card {
    background-color: none;
  }
}
</style>
